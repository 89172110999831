import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "./dashboardLayout"; // Assuming you have a layout component

const MerchantsRegistered = () => {
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    const fetchMerchants = async () => {
      const token = sessionStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllRegisteredMerchants`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setMerchants(response.data.data.allRegisteredMerchant); // Updated to match your API response
        } else {
          console.error("Error fetching merchants:", response.data.message);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchMerchants();
  }, []);

  return (
    <DashboardLayout>
      <div className="p-6 bg-gray-100 min-h-screen">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">
          Merchants Registered
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {merchants.length > 0 ? (
            merchants.map((merchant) => (
              <div
                key={merchant._id}
                className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col justify-between"
              >
                <div className="p-4 flex-grow">
                  <h2 className="bg-green-200 p-3 text-xl font-bold text-gray-800 mb-4 rounded-t-lg">
                    {merchant.shopName || "Unnamed Shop"}
                  </h2>
                  <p className="text-gray-700 mb-4">
                    <strong>Address:</strong> {merchant.addressLine1 || "N/A"},{" "}
                    {merchant.addressLine2 || "N/A"}
                  </p>
                </div>
                <div className="p-4">
                  <p className="text-gray-700 mb-4">
                    <strong>Owner Mobile Number:</strong>{" "}
                    <span className="text-blue-700 font-medium">
                      {merchant.ownerMobileNumber}
                    </span>
                  </p>
                  <a
                    href={`tel:${merchant.ownerMobileNumber}`}
                    className="text-green-600 hover:text-green-800 font-medium text-sm"
                  >
                    Call Owner
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-700">No merchants registered.</p>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MerchantsRegistered;
