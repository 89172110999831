import { useState, useEffect } from "react";
import axios from "axios";
import PieChartComponent from "./pieChart";

const OrderSummary = () => {
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [refundedCount, setRefundedCount] = useState(0);
  const [confirmedCount, setConfirmedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchOrdersByStatus = async (status) => {
    const token = sessionStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/allOrderDetails`,
        {
          filterKey: status,
          page: 1,
          size: 1000,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        const filteredOrders = response.data.data.orders.filter(
          (order) => order.merchantShopName !== "Demo Account"
        );
        return filteredOrders.length;
      }
    } catch (error) {
      console.error(`Error fetching ${status} orders:`, error);
      return 0;
    }
  };

  const fetchAllOrders = async () => {
    setLoading(true); // Show loading state when refreshing
    try {
      const [delivered, refunded, confirmed, pending] = await Promise.all([
        fetchOrdersByStatus("delivered"),
        fetchOrdersByStatus("refunded"),
        fetchOrdersByStatus("confirmed"),
        fetchOrdersByStatus("pending"),
      ]);

      // Update the state with the counts
      setDeliveredCount(delivered);
      setRefundedCount(refunded);
      setConfirmedCount(confirmed);
      setPendingCount(pending);
    } catch (error) {
      setError("An error occurred while fetching order counts.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <>
      <div>
        <PieChartComponent
          deliveredCount={deliveredCount}
          refundedCount={refundedCount}
          canceledCount={confirmedCount}
          pendingCount={pendingCount}
        />
        {/* <button
          onClick={fetchAllOrders}
          className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Refresh
        </button> */}
      </div>
    </>
  );
};

export default OrderSummary;
