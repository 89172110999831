import React from "react";
import DNavbar from "../components/navbar-delivery";

export default function DeliveryTC() {
  return (
    <>
      <DNavbar />
      <div className="px-8 lg:px-28 text-justify">
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Delivery Terms & Conditions
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules thereunder as applicable and the
          provisions pertaining to electronic records in various statutes as
          amended by the Information Technology Act, 2000. This electronic
          record is generated by a computer system & does not require any
          physical or digital signatures. By clicking on the “I ACCEPT” button,
          You are consenting to be bound by these Delivery Partner T&C for using
          the Vyloo DP Platform for the purpose of receiving orders and
          delivering the medicine to the Users. PLEASE ENSURE THAT YOU READ AND
          UNDERSTAND ALL THESE DELIVERY PARTNER T&C BEFORE YOU USE THE SERVICE
          OF THE VYLOO DP PLATFORM, AS YOU SHALL BE BOUND BY ALL THE DELIVERY
          PARTNER T&C CONTAINED HEREIN upon clicking on the “I ACCEPT” button.
          If You do not accept any of the Delivery Partner T&C, please do not
          use the Vyloo DP Platform or avail any of the services being provided
          therein. YOUR AGREEMENT TO THE DELIVERY PARTNER T&C SHALL OPERATE AS A
          BINDING AGREEMENT BETWEEN YOU AND VYLOO IN RESPECT OF THE SERVICES OF
          THE VYLOO DP PLATFORM.
          <br />
          <br />
          Your engagement with Vyloo to use the services of the Vyloo DP
          Platform are subject to your acceptance of these Delivery Partner T&C.
          Vyloo reserves the right, at its sole discretion, to change, modify,
          add or remove these Delivery Partner T&C, in part or in whole, at any
          time, without prior notice to You. It is Your responsibility and duty
          to check the Delivery Partner T&C periodically for changes. Your
          continued use of the Vyloo DP Platform following the posting of
          changes will mean that You accept and agree to the changes. As long as
          You comply with these Delivery Partner T&C, Vyloo grants You a
          personal, non-exclusive, non-transferable, limited privilege to enter
          and use the service on the Vyloo DP Platform.
          <br /> <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            1. DEFINITIONS
          </h1>
          <hr className="w-auto py-2" />
          <span className="font-black">“Acceptance”</span> means your
          affirmative action of clicking on the box against the words “ I
          Accept” provided at the end of these Delivery Partner T&C, by which
          action, you unequivocally accept the Delivery Partner T&C and any
          modifications thereof;
          <br />
          <br />
          <span className="font-black">“Agreement”</span> shall mean the
          Delivery Services Availability Agreement executed between Vyloo and
          the Delivery Partner pursuant to which the Delivery Partner has agreed
          to undertake Delivery Services.
          <br />
          <br />
          <span className="font-black">“Applicable Law”</span> shall mean and
          include any law, statute, ordinance, rule, regulation, guideline,
          policy or other pronouncement having the effect of law in India, as in
          effect or which may come in effect on a future date;
          <br />
          <br />
          <span className="font-black">“Delivery Partner”</span> or{" "}
          <span className="font-black">“ You”</span> or{" "}
          <span className="font-black">“ Your”</span> or{" "}
          <span className="font-black">“ Yourself”</span> shall mean an
          individual who has executed Agreement for the provision of Delivery
          Services;
          <br />
          <br />
          <span className="font-black">
            “Delivery Partner Information”
          </span>{" "}
          shall mean and include any personal data collected from the Delivery
          Partner including know your customer documents with Delivery Partner’s
          bank, copies of valid government issued vehicle registration
          certificate, vehicle insurance copy, driving license, identity proof,
          residence proof, location data, proof of ownership of Delivery Partner
          Vehicle and any other information that Vyloo may deem fit;
          <br />
          <br />
          <span className="font-black">“Delivery Partner Vehicle”</span> shall
          have the meaning assigned to it in Clause 5(i).
          <br />
          <br />
          <span className="font-black">“Delivery Services”</span> shall mean
          either or both of the following as the context may require:
          <br />
          <br />
          a. The services provided by the Delivery Partner to Vyloo of making
          himself available on the Vyloo DP Platform for the purpose of
          receiving orders placed by the Users.
          <br />
          b. The delivery services provided by the Delivery Partner to the Users
          whereby the Delivery Partner collects the medicine ordered by the
          Users and delivers the same to the Users.
          <br />
          <br />
          <span className="font-black">
            “Intellectual Property Rights”
          </span>{" "}
          shall mean and include the copyright (whether registered or not),
          patents including rights of filing patents, trademarks, trade names,
          trade dresses, house marks, collective marks, associate marks and the
          right to register them, designs ( both industrial and layout),
          geographical indicators, moral rights, broadcasting rights, displaying
          rights, distribution rights, selling rights, reproducing rights,
          domain names, internet address, graphics, artwork, links, information,
          logos, software, configuration, marketing and distribution material
          and any other intellectual property right in the website of Vyloo, and
          Vyloo Platform, which are used and owned by Vyloo;
          <br />
          <br />
          <span className="font-black">“Lead Business Associate”</span> shall
          mean a person designated by Vyloo responsible for ensuring all
          questions and queries of Delivery Partners operating within a defined
          territorial area are answered as well as providing all necessary
          operational support that such Delivery Partners may require;
          <br />
          <br />
          <span className="font-black">“Platform Services”</span> shall have the
          meaning assigned to it in the Agreement;
          <br />
          <br />
          <span className="font-black">“Termination Date”</span> shall mean the
          date on which these Delivery Partner T&C and the Agreement shall stand
          terminated;
          <br />
          <br />
          <span className="font-black">“Terms and Conditions”</span> or{" "}
          <span className="font-black">“ Delivery Partner T&C”</span> refers to
          these Delivery Partner Terms and Conditions, which are available on
          the Vyloo DP Platform, as may be amended from time to time;
          <br />
          <br />
          <span className="font-black">“Vyloo”</span> or{" "}
          <span className="font-black">“ We”</span> or{" "}
          <span className="font-black">“ Us”</span> or{" "}
          <span className="font-black">“ Our”</span> shall mean Vyloo Media
          Private Limited, a company incorporated under the Companies Act, 1956,
          and having its registered office at 10251 GALI MILLWALI , LIBRARY ROAD
          ,AZAD MARKET, DELHI 110006 which expression shall, unless it be
          repugnant to the context or meaning thereof, be deemed to mean and
          include all our successors, affiliates and assigns;
          <br />
          <br />
          <span className="font-black">“Vyloo DP Platform”</span> shall have the
          meaning assigned to it in the Agreement. It shall also include for all
          purposes the mobile interface provided to the Delivery Partner wherein
          all the back-end technology is incorporated to enable the Delivery
          Partner to provide Delivery Services as well as enable Vyloo, to track
          the orders and other relevant information;
          <br />
          <br />
          1. Unless the context of these Delivery Partner T&C otherwise
          requires:
          <br />
          <br />
          i. A word or an expression, which denotes a natural person shall
          include an artificial person (and vice versa), any one gender shall
          include the other genders, the singular shall include the plural (and
          vice versa).
          <br />
          ii. Heading and bold typeface in these Delivery Partner T&C are for
          convenience only and shall be ignored for the purposes of
          interpretation.
          <br />
          iii. The use of the word “including” followed by a specific example(s)
          in these Delivery Partner T&C, shall not be construed as limiting the
          meaning of the general wording preceding it.
          <br />
          iv. The rule of construction, if any, that a contract should be
          interpreted against the parties responsible for drafting and
          preparation thereof, shall not apply.
          <br />
          v. Reference to any clause, article, section or schedule shall mean a
          reference to a clause, article, section or schedule of these Delivery
          Partner T&C, unless specified otherwise.
          <br />
          vi. Reference to any clause, article, section or schedule shall mean a
          reference to a clause, article, section or schedule of these Delivery
          Partner T&C, unless specified otherwise.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            2. APPLICABILITY OF DELIVERY PARTNER T&C
          </h1>
          <hr className="w-auto py-2" />
          These Delivery Partner T&C together with the Agreement shall form the
          complete understanding between the Parties. By accepting these
          Delivery Partner T&C, You acknowledge and agree to the Delivery
          Partner T&C and Vyloo policies that Vyloo makes applicable to You from
          time to time, to the fullest extent possible. Unless defined herein,
          the words, phrases and capitalized terms, which are contained or
          referred to in these Delivery Partner T&C, shall be construed as
          having the meaning thereby attributed to them in the Agreement.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            3. SERVICE AND CHARGES
          </h1>
          <hr className="w-auto py-2" />
          i. Subject to terms and conditions contained in these Delivery Partner
          T&C and the Agreement, the Delivery Partner agrees to makes himself
          available on the Vyloo DP Platform to undertake Delivery Services as
          and when a request for the same is placed by a User through the Vyloo
          DP Platform, and to provide Delivery Services to the Users to the best
          of his abilities. <br />
          <br />
          ii. Delivery Partner agrees and acknowledges that the upon acceptance
          of an order by the Delivery Partner, Delivery Services undertaken by
          him shall constitute a separate contract for services under the
          Consumer Protection Act, 2019 or any successor legislations, between
          the Delivery Partner and the User, to which Vyloo is not a party.{" "}
          <br />
          <br />
          iii. The Delivery Partner shall create a log-in ID on the Vyloo DP
          Platform to be able to render Delivery Services. The Delivery Partner
          acknowledges that the sign-in details, including the username and
          password, are confidential and, accordingly, shall not share them with
          any third party, without written approval from Vyloo.
          <br />
          <br />
          iv. The Delivery Partner shall have access to the Vyloo DP Platform at
          all times unless there is a technical glitch or if the Vyloo DP
          Platform is being updated. Once logged-in, the Delivery Partner shall
          remain available and shall be able to connect with the Users for the
          purpose of receiving orders placed by the Users and undertaking
          Delivery Services in connection with the same.
          <br />
          <br />
          v. The Delivery Partner confirms and acknowledges that by logging-in
          on the Vyloo DP Platform, he agrees to be tracked by Vyloo via GPS
          enabled tracking technology and Vyloo may share this information with
          Users on a real-time basis for the purpose of enabling the status of
          the Delivery Services being provided by the Delivery Partner to the
          Users. The Delivery Partner further agrees that logging-in on the
          Vyloo DP Platform shall be deemed acceptance of the Delivery Partner’s
          intention to accept orders of Users to render the Delivery Services
          mentioned herein.
          <br />
          <br />
          vi. All reasonable attempts shall be made by the Delivery Partner to
          collect the order from the Pharmacy Partners and deliver it to the
          Users, as quickly and efficiently as possible. <br />
          <br />
          vii. In consideration for the Delivery Services provided by the
          Delivery Partner to the Users of delivering medicineto the Users, the
          Delivery Partner may charge the Users a service fee (“Delivery
          Charges”), subject to such Delivery Charges being accepted by the User
          at the time of placement of the order. The Delivery Charges shall be
          agreed between the Delivery Partner and Vyloo from time to time, in
          the manner provided under these Delivery Partner T&C.
          <br />
          <br />
          viii. In order to incentivize the Delivery Partner for the
          availability committed by him/her on the Vyloo DP Platform to
          undertake Delivery Services as and when he is connected to a User,
          Vyloo may, at its discretion, pay the Delivery Partner a fee
          (“Availability Fee”), in addition to the Delivery Charges collected by
          the Delivery Partner from the Users. <br />
          <br />
          ix. Notwithstanding anything to the contrary under Clause 3(ix) above,
          the Delivery Partner hereby absolutely, unconditionally and
          irrevocably authorizes Vyloo to determine the amounts chargeable by
          the Delivery Partner as Delivery Charges, through such means as Vyloo
          may determine, including real-time analysis of the demand for Delivery
          Services, the availability of delivery partners on the Vyloo DP
          Platform to provide Delivery Services to the Users, traffic and
          weather conditions, seasonal peaks and such other factors as Vyloo may
          deem fit, and you agree that the Delivery Charges may be revised from
          time to time on the basis of one or more of these factors. Vyloo
          shall, from time to time and through such means as Vyloo may deem fit,
          notify You the Delivery Charges that You may charge the Users.
          <br />
          <br />
          x. You shall not charge the Users any amount over and above the amount
          of Delivery Charges agreed between You and Vyloo under these Delivery
          Partner T&C.
          <br />
          <br />
          xi. You hereby authorize Vyloo to collect from the Users, on your
          behalf, the Delivery Charges charged by You to the Users for
          undertaking Delivery Services, which shall be remitted to you on a
          weekly basis through such mode and on such day(s) of a week as Vyloo
          may from time to time decide. <br />
          <br />
          xii. Vyloo may, at its discretion, pay the Delivery Partner an
          additional amount and/or incentive (in addition to what Vyloo collects
          from the Users) for the Delivery Services provided by the Delivery
          Partner using the Vyloo DP Platform.
          <br />
          <br />
          xiii. Vyloo may, at any time: (a) set off and deduct any amounts due
          from, payable by or proposed to be paid by Vyloo to the Delivery
          Partner, including the Delivery Charges collected by Vyloo from the
          Users on behalf of the Delivery Partner and the Availability Fee; and
          (b) apply such amounts towards any amounts due from, or payable by the
          Delivery Partner under or in connection with the Agreement and the
          Delivery Partner T&C, including without limitation the Platform
          Charges. Vyloo shall have the right and the obligation to pay only
          such amounts due and payable by Vyloo to the Delivery Partner, if any
          amount so remains due and payable after such set off, deduction and
          application as set out under this Clause 3(xvii). Nothing in this
          Clause 3(xvii) shall prejudice any right or remedy available to Vyloo,
          whether under contract, law or equity to recover any amounts due from,
          or payable by the Delivery Partner under or in connection with the
          Agreement and the Delivery Partner T&C, whether such amounts arise
          under contract, tort, statute or equity. <br />
          <br />
          xiv. Notwithstanding anything contained in these Delivery Partner T&C,
          there shall be no obligation on the Delivery Partner to be available
          on Vyloo DP Platform for a minimum number of hours/days. The Delivery
          Partner acknowledges that he has flexible timings and can choose to
          log-in the Vyloo DP Platform anytime he wants and for however long he
          wants, at his sole discretion. Vyloo shall also not supervise the
          actions and conduct of the Delivery Partners unless they are in gross
          violation of their duty to render Delivery Services under these
          Delivery Partner T&C or the Agreement. All queries and questions
          raised by the Delivery Partner, including but not limited to how to
          collect an order from the outlet of a Pharmacy Partner, directions for
          the delivery address, manner to keep and store the medicine while in
          transit shall be directed to a Lead Business Associate for the
          particular area from where the Delivery Partner is operating to render
          the Delivery Services.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">4. Tax</h1>
          <hr className="w-auto py-2" />
          i. You authorize Vyloo to make Tax Deduction at Source (TDS) as per
          the Income Tax Act, 1961, where applicable, and other applicable taxes
          from the amount paid or remitted to You under the Agreement or these
          Delivery Partner T&C, including the Delivery Charges collected by
          Vyloo on your behalf from the Pharmacy Partners and the Users, which
          includes the following:
          <br />
          <br />
          ii. In case any withholding tax has to be deducted from the Delivery
          Charges or any amount payable by Vyloo under the Agreement and these
          Delivery Partner T&C, the Company shall deduct the same and provide
          the Business Associate with adequate proof of depositing the said
          withholding tax with the Indian tax authorities in accordance with the
          Income Tax Act, 1961. If Vyloo is required to withhold any payment
          under Applicable Law from any amount due or payable to the Delivery
          Partner under or in connection with the Agreement and the Delivery
          Partner T&C, Vyloo shall be entitled to deduct the same and deal with
          it in such manner as may be required under Applicable Law.
          <br />
          <br />
          iii. Vyloo shall raise a tax invoice containing such particulars as
          may be prescribed under the Goods and Service Tax Act, 2017 and the
          rules made thereunder, as amended from time to time for any amount
          charged to you by Vyloo including the Platform Charges. The Platform
          Charges shall be includive of applicable taxes.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            5. OBLIGATIONS OF DELIVERY PARTNER
          </h1>
          <hr className="w-auto py-2" />
          i. Delivery Partner makes himself/herself available to undertake
          Delivery Services as and when a request for the same is placed by User
          through the Vyloo DP Platform.
          <br />
          <br />
          ii. If required, the Delivery Partner shall use his personal vehicle
          (“Delivery Partner Vehicle”) for providing Delivery Services. No
          vehicle of any kind shall be provided by Vyloo under any
          circumstances.
          <br />
          <br />
          iii. The Delivery Partner shall ensure that the Delivery Partner
          Vehicle is well maintained and in good condition so that there are no
          delays in rendering Delivery Services.
          <br />
          <br />
          iv. All expenses incurred in maintaining, running and riding the
          Delivery Partner Vehicle shall be borne exclusively by the Delivery
          Partner unless otherwise agreed by Vyloo.
          <br />
          <br />
          v. The Delivery Partner shall hold and possess a valid driving license
          and a valid registration number for the Delivery Partner Vehicle, if
          required under the Applicable Law for the vehicle used by the Delivery
          Partner for providing Delivery Services, which are up to date and in
          subsistence throughout the Term of these Delivery Partner T&C. Copies
          of the driving license as well as the registration certificate of the
          Delivery Partner Vehicle, including any other Delivery Partner
          Information, shall be handed to the Vyloo before commencing Delivery
          Services or at any other time deemed appropriate by Vyloo.
          <br />
          <br />
          vi. The Delivery Partner shall have a valid and adequate insurance
          coverage to the Delivery Partner Vehicle. Delivery Partner shall
          ensure that the insurance is valid, up to date and in subsistence
          throughout the Term of these Delivery Partner T&C. A copy of the
          insurance policy shall be given by the Delivery Partner to the Vyloo.
          The Delivery Partner further confirms that all premium payments
          towards the insurance policy shall be paid exclusively by the Delivery
          Partner. Under no circumstances shall Vyloo be liable to make any
          payment with respect to such insurance.
          <br />
          <br />
          vii. During the course of undertaking Delivery Services, the Delivery
          Partner shall conduct himself with honesty, discipline and in
          accordance with the policies and instructions of the Vyloo, whether
          presently in force or adopted in the future, including but not limited
          to safety, driving rules, etc. The Delivery Partner shall also comply
          with all Applicable Law including the provisions of the Motor Vehicles
          Act, 1988 and its corresponding rules.
          <br />
          <br />
          viii. Delivery Partner shall not commit any fraud while providing
          Delivery Services or otherwise commit any act or omission, to gain any
          undue advantage. Delivery Partner agrees and acknowledges that in case
          Vyloo believes that the Delivery Partner has committed any of the
          foregoing while undertaking Delivery Services, Vyloo shall, in
          addition to its right to terminate the Agreement and these Delivery
          Partner T&C, in its sole discretion reserve the right to (a) disable
          the Delivery Partner from undertaking Delivery Services through the
          Vyloo DP Platform for such time as it deems fit and /or (b) deduct the
          undue gain made by the Delivery Partner through the fraudulent orders
          from the payout including incentives thereof and/or the Security
          Deposit, if any. This right to withhold payout including incentives
          thereof may also be exercised by Vyloo in the event service parameter
          guidelines, as prescribed by the Vyloo from time to time, is not met.
          <br />
          <br />
          ix. At no time whatsoever shall the Delivery Partner tamper, damage,
          open or do anything to the medicine orders of the Users that he/she is
          not specifically permitted to do. In case Vyloo suffers any loss due
          to the Delivery Partner tampering, damaging or opening the packaging
          of medicine orders of the Users, Vyloo shall have the right to recover
          any loss, if any, from the payments required to be made by Vyloo to
          the Delivery Partner under the Agreement or these Delivery Partner
          T&C.
          <br />
          <br />
          x. Where the Delivery Partner is required, under instructions from
          Vyloo, to use his personal cash for the purpose of paying the Pharmacy
          Partner to pay the Pharmacy Partner for the value of the order for a
          cash on delivery order, then, the Delivery Partner shall collect the
          original receipt from the Pharmacy Partner and re-collect his payment
          from the User by handing the original receipt. Without prejudice to
          the preceding sentence of this Clause 5(x), any amount collected by
          the Delivery Partner from the Users, on behalf of, and due to Vyloo,
          shall be deposited by the Delivery Partner with Vyloo in such manner
          and at such frequencies as Vyloo may instruct from time to time.
          <br />
          <br />
          xi. Notwithstanding Clause 5(x), where the Delivery Partner is
          required to collect the value of the order from the User but is not
          required to pay the value of the order to the Pharmacy Partner, then
          any amount collected by the Delivery Partner from the Users, on behalf
          of and due to Vyloo, shall be deposited by the Delivery Partner with
          Vyloo in such manner and at such frequencies as Vyloo may instruct
          from time to time.
          <br />
          <br />
          xii. The Delivery Partner shall undertake the Delivery Services by
          himself and shall not delegate the same to any individual or third
          party.
          <br />
          <br />
          xiii. The welcome kit shall be used in a prudent manner, so as not to
          destroy or damage any of its contents. Upon receiving any order to
          render Delivery Services after logging-in the Vyloo DP Platform, the
          Delivery Partner shall act and perform his role in an ethical manner
          and to the best of his abilities by ensuring a timely pick-up and
          delivery. In case of any delays due to traffic, the Delivery Partner
          shall inform the Pharmacy Partner and the User and follow instructions
          as given by them.
          <br />
          <br />
          xiv. The Delivery Partner acknowledges that the goodwill and
          reputation of Vyloo is at stake with how effectively and efficiently
          the Delivery Partner renders Delivery Services pursuant to these
          Delivery Partner T&C. Accordingly, the Delivery Partner shall not do
          any act that adversely affects Vyloo and undertakes to be in
          compliance with Applicable Law at all times and protect the brand
          image, business reputation or any other asset/property of Vyloo.
          <br />
          <br />
          xv. While logged-in the Vyloo DP Platform, the Delivery Partner shall
          not engage in any illegal activity or perform any actions that are
          contrary to Applicable Law.
          <br />
          <br />
          xvi. All Confidential Information procured shall at all times be kept
          confidential and used only for the limited permitted purposes of
          rendering Delivery Services.
          <br />
          <br />
          xvii. The Delivery Partner is not entitled to claim reimbursement of
          hospitalization/ hospital bills that may be incurred by the Delivery
          Partner while rendering Delivery Services or incurred by his family
          members for any unfortunate accidents or severe illness, during the
          subsistence of Agreement and these Delivery Partner T&C.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            6. OBLIGATIONS OF VYLOO
          </h1>
          <hr className="w-auto py-2" />
          i. All Delivery Partner Information procured shall be kept
          confidential and used only as per these Delivery Partner T&C and the
          Agreement.
          <br />
          <br />
          ii. Vyloo shall make all reasonable efforts to ensure that the Vyloo
          DP Platform is running at all times. However, it shall not be liable
          for any technical glitches or updates due to which a Delivery Partner
          is unable to log-in the Vyloo DP Platform or receive orders.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            7. DELIVERY PARTNER INFORMATION
          </h1>
          <hr className="w-auto py-2" />
          i. Vyloo may collect Delivery Partner Information at the time of
          execution of these Delivery Partner T&C or at any time thereafter, to
          establish the identity of the Delivery Partner. Vyloo reserves the
          right to store, process, access and use the Delivery Partner
          Information for business purposes and needs, background check,
          verification, marketing, service, development, analytics, research,
          and any other purpose as Vyloo may deem fit and in accordance with
          Applicable Law. The Delivery Partner hereby expressly consents to such
          collection and use of Delivery Partner Information.
          <br />
          <br />
          ii. Subject to Applicable Law, Vyloo may provide to a third party,
          governmental agency, judicial body, any Delivery Partner Information
          or information relating to Delivery Partner Services, if there is a
          complaint, dispute or conflict, including any accident involving a
          Delivery Partner on one hand and end-consumer, or a third party on the
          other hand;
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            8. INTELLECTUAL PROPERTY RIGHTS
          </h1>
          <hr className="w-auto py-2" />
          i. The Parties hereby agree that all Intellectual Property Rights
          shall be in absolute ownership, possession and control of Vyloo and
          the Delivery Partner is only permitted to use such Intellectual
          Property Rights in connection with Delivery Services rendered under
          these Delivery Partner T&C as are specifically permitted by the Vyloo.
          The Parties hereby clarify that no license or rights is granted in the
          Intellectual Property Rights to the Delivery Partner under these
          Delivery Partner T&C, by implication or otherwise.
          <br />
          <br />
          ii. Notwithstanding anything contained in these Delivery Partner T&C,
          in the event the Delivery Partner uses the Intellectual Property
          Rights in such manner so as to license, sub-license, create derivative
          Intellectual Property Rights, use it otherwise not being in connection
          with Delivery Services rendered under these Delivery Partner T&C, the
          same shall constitute a breach of these Delivery Partner T&C and Vyloo
          reserves its rights to resort to legal proceedings against the
          Delivery Partner for recovering damages and losses suffered or likely
          to be suffered.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            9. REPRESENTATIONS AND WARRANTIES
          </h1>
          <hr className="w-auto py-2" />
          9.1 The Delivery Partner represents and warrants as follows:
          <br />
          <br />
          i. The Delivery Partner is capable of entering in the present
          Agreement, pursuant to the Indian Contract Act, 1872 and is not below
          the age of 18 years as on the Effective Date.
          <br />
          <br />
          ii. All Delivery Partner Information provided shall be true and
          correct and no information that could impact the Delivery Partner’s
          performance to render Delivery Services pursuant to these Delivery
          Partner T&C and shall not be hidden from the Vyloo anytime during the
          subsistence of these Delivery Partner T&C.
          <br />
          <br />
          iii. The Delivery Partner has not been convicted by any court in India
          or any other country of any crimes including but not limited to
          involving moral turpitude. Further, the Delivery Partner is not a
          party to any pending litigation, which shall materially affect Your
          obligations under these Delivery Partner T&C.
          <br />
          <br />
          9.2 Vyloo hereby represents and warrants as follows:
          <br />
          <br />
          i. It is fully capable of executing these Delivery Partner T&C and
          Delivery Service Agreement and has the necessary authority.
          <br />
          <br />
          ii. It shall not exercise operational supervision on the activities of
          the Delivery Partner as they are free to determine how to render
          Delivery Services so long as the Vyloo’s reputation and goodwill is
          not damaged.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            10. TERMINATION
          </h1>
          <hr className="w-auto py-2" />
          10.1 Vyloo reserves the right to terminate these Delivery Partner T&C
          and/or the Agreement and deny the Delivery Partner access to Vyloo DP
          Platform at any time for any reason. The date on which the Delivery
          Partner’s access to the Vyloo DP Platform is intentionally blocked by
          the Vyloo shall be considered as the Termination Date of these
          Delivery Partner T&C and the Agreement.
          <br />
          <br />
          10.2 Without prejudice to the generality of the foregoing clause,
          Vyloo reserves the right to terminate these Delivery Partner T&C and
          the Agreement with immediate effect for:
          <br />
          <br />
          i. Any breach of the terms of these Delivery Partner T&C or the
          Agreement by the Delivery Partner;
          <br />
          <br />
          ii. failure to verify or authenticate Delivery Partner Information;
          and
          <br />
          <br />
          iii. any action or omission by the Delivery Partner which can cause
          legal or contractual liability for Vyloo including but not limited to
          fraudulent conduct, customer complaints, continuous unsatisfactory
          reviews by the Pharmacy Partners or the Users, misconduct, negligence,
          and all other actions specifically prohibited under Applicable Law.
          <br />
          <br />
          iv. Vyloo is also at liberty to terminate the contract at their
          discretion, where, in their opinion, continuance of the services of
          Delivery Partner is detrimental to the business interest of Vyloo due
          to the acts of the Delivery Partners, such as the following:
          <br />
          <br />
          a. Misbehavior, rude behavior with the staff of Vyloo, Pharmacy
          Partners, Users or any other persons associated with the Vyloo.
          <br />
          <br />
          b. Any acts involving criminal offence punishable under law, including
          physical assault, threatening any of the staff of Vyloo, person
          associated with Vyloo and any other persons.
          <br />
          <br />
          c. Concealment of fact / material information while entering into
          contract with Vyloo.
          <br />
          <br />
          d. Poor & irregular for work, meetings and failure to abide by the
          rules / terms of contract.
          <br />
          <br />
          e. Drunk while on duty and drunken behavior.
          <br />
          <br />
          f. Poor performance for two consecutive weeks. Performance includes -
          daily productivity, login hour, idle hours, number of deliveries
          completed, quality standards such as denying/ fast forwarding/
          cancelling the order placed by a Pharmacy Partner.
          <br />
          <br />
          g. Commission of fraud/ misappropriation/embezzlement for undue
          monetary gain, which is against the interest of the Vyloo.
          <br />
          <br />
          h. Negligence in performing the duty, causing damage of moveable and
          immoveable assets of Vyloo, its employees, Users.
          <br />
          <br />
          i. Indulging in spreading content through digital media, social
          networking sites, or any other form, which could be detrimental to
          Vyloo’s brand and its image.
          <br />
          <br />
          j. Indulging in acts such as creating ruckus/ strike/ or any activity
          against Vyloo, which could be detrimental to the Vyloo’s brand and its
          image.
          <br />
          <br />
          k. Indulging in unauthorized disclosure of Confidential Information of
          Vyloo to external agency, person, Vyloo or organization.
          <br />
          <br />
          l. Misuse of assets provided by Vyloo and welcome kits, which could be
          detrimental to the interest of Vyloo’s brand and its image.
          <br />
          <br />
          m. Absconding for more than 4 hours with any asset, delivery item,
          money or any other valuable item belonging to Vyloo, its employees,
          Users, Pharmacy Partner(s) and/ or other staff member(s).
          <br />
          <br />
          n. Failure to abide by any of the rules and guidelines given by Vyloo
          as part of service quality standards and principles.
          <br />
          <br />
          o. Doing any act unbecoming of a Delivery Partner.
          <br />
          <br />
          p. In case the background check, whether wholly or partially, is found
          negative at any point of time during the term of these Delivery
          Partner T&C.
          <br />
          <br />
          10.4 Notwithstanding anything contained in this Clause, Vyloo reserves
          the right to recover any amounts due and owed by the Delivery Partner
          and take appropriate legal actions that may be available under
          Applicable Law and equity for recovery of any amounts due
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            12. COMPLIANCE WITH APPLICABLE LAW
          </h1>
          <hr className="w-auto py-2" />
          The Delivery Partner agrees and consents to comply with all Applicable
          Law at all times while providing the Delivery Services and while
          accessing the Vyloo DP Platform.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            13. AMENDMENT
          </h1>
          <hr className="w-auto py-2" />
          Vyloo reserves the right to change, modify, reinstate, amend or delete
          any terms and conditions contained in these Delivery Partner T&C,
          without prior notice, at any time and in its sole discretion, by
          posting a change notice or a new agreement on the Vyloo DP Platform.
          The Delivery Partner shall be responsible for keeping himself apprised
          and informed of the revised terms and conditions at all times.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            14. RELATIONSHIP OF THE PARTIES
          </h1>
          <hr className="w-auto py-2" />
          The Delivery Partner is an independent business contractor and this
          Delivery Partner T&C is a principal-to-principal contract. It shall
          not create any partnership, joint venture, employment, agency,
          franchise, sales representative or any vicarious and absolute
          liability relationship between the parties. It is clarified between
          the parties that neither of the parties shall be liable for any action
          or omission of the other party in any manner. It is clearly understood
          and agreed that under these Delivery Partner T&C no relationship of
          employer and employee exists between Vyloo and Delivery Partner. The
          Delivery Partner shall not have any claim for permanent employment or
          employment benefits under any statutes / local laws.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            15. INDEMNIFICATION AND LIMITATION OF LIABILITY
          </h1>
          <hr className="w-auto py-2" />
          i. You agree and undertake to indemnify and to hold harmless the Vyloo
          its affiliates, successors, agents, assigns, and each of their
          directors, officers, employees, associates, agents, and
          representatives or any third party from and against any losses,
          damages, liability, claims, costs, penalty and expenses (including,
          without limitation, reasonable attorneys’ fees) incurred by reason of
          (i) any breach or alleged breach by You of your obligations,
          performance or observance of your role, functions, responsibilities,
          representations, or warranties under the Delivery Partner T&C and the
          Agreement; (ii) any violation of Vyloo’s policies; (iii) any act or
          omission that causes or may cause harm to the reputation and goodwill
          of Vyloo; (iv) any claim of violation of intellectual property of
          Vyloo or any third party by your usage of Intellectual Property Rights
          in a manner not permitted under the Agreement and these Delivery
          Partner T&C; (v) your misconduct or unauthorized access or use of the
          User data on the Vyloo DP Platform or by the transferring of such data
          to any third party or unauthorized disclosure or use of Confidential
          Information of Vyloo; (vii) any act of theft, fraud, negligence and
          misconduct by you; (viii) any damage to the Pharmacy Partner’s or
          User’s property or any asset; and (ix) any misbehavior towards the
          Pharmacy Partner, Users or Vyloo and its employees or tampering with
          the medicinepackaging while performing the Delivery Services.
          <br />
          <br />
          ii. You shall be liable to indemnify and hold Vyloo harmless against
          all damages, losses, costs and expenses incurred by Vyloo as a
          consequence of any complaint from any User and/or Pharmacy Partner
          received by Vyloo with respect to any error or deficiency in the
          Delivery Services.
          <br />
          <br />
          iii. In addition to the indemnification rights of Vyloo under these
          Delivery Partner T&C, Vyloo shall also be entitled to such other
          remedies available under Applicable Law for breach of contract where
          time is of essence.
          <br />
          <br />
          iv. In no event will Vyloo be liable for any losses arising from or in
          connection with these Delivery Partner T&C and the Agreement, pursuant
          to any claim by the Delivery Partner against Vyloo under contract,
          tort or otherwise, if such losses could have been avoided by the
          Delivery Partner using reasonable efforts to mitigate them. Further,
          the Vyloo shall also not be liable to the Delivery Partner in
          contract, tort or otherwise for indirect, special, incidental,
          exemplary, punitive, or consequential damages of any kind whatsoever
          even if advised of the possibility of such damages. Notwithstanding
          anything contrary contained elsewhere in the Agreement, the total
          cumulative liability of the Vyloo to the Delivery Partner or to any
          person claiming under contract, tort, or otherwise, shall not exceed
          an amount of INR 10,000/- (Rupees Ten Thousand only).
          <br />
          <br />
          v. Vyloo shall not be responsible or liable for any loss or damage,
          howsoever caused or suffered by the Delivery Partner arising out of
          the use of the Platform Services offered by the Vyloo to the Delivery
          Partner directly or indirectly, for any reason whatsoever, including
          but not limited to damage or loss caused to the Delivery Partner as a
          result of a Pharmacy Partner/User’s non-compliance, which includes,
          but is not limited to, any incorrectly placed voice instructions,
          malfunction, partial or total failure of any network terminal, data
          processing system, computer tele-transmission or telecommunications
          system or other circumstances whether or not beyond the control of
          Vyloo or any person or any organization involved in the above
          mentioned systems. Without prejudice to the above, Vyloo shall not be
          liable for any direct or indirect loss or damage, which may be
          suffered by the Delivery Partner as a result of any failure by a User
          to show up within any stipulated time even if Vyloo has agreed to such
          timing or even if the User has advised Vyloo of the possibility that
          he / she may not show up within the stipulated time.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            16. SPECIFIC INDEMNITY
          </h1>
          <hr className="w-auto py-2" />
          The Delivery Partner shall be solely liable for any and all
          accidents/incidents involving the Delivery Partner Vehicle, while
          providing the Delivery Services. Vyloo shall not be held liable for
          any such accidents/ incidents involving the Delivery Partner.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            17. SET-OFF
          </h1>
          <hr className="w-auto py-2" />
          In addition to any other remedies provided under a contract including
          the Agreement and these Delivery Partner T&C or provided by law or in
          equity, Vyloo may, at any time, without notice to the Delivery
          Partner, set off any liability of the Delivery Partner to Vyloo
          against any liability of Vyloo to the Delivery Partner, whether either
          liability is present or future, liquidated or unliquidated, and
          whether or not either liability arises under the Agreement or these
          Delivery Partner T&C.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            18. DISPUTE RESOLUTION
          </h1>
          <hr className="w-auto py-2" />
          1. These Delivery Partner T&C and the Agreement shall be governed by
          laws of India.
          <br />
          <br />
          2. The courts of New Delhi shall have exclusive jurisdiction over all
          disputes arising from these Delivery Partner T&C and the Agreement.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            19. PENALTY
          </h1>
          <hr className="w-auto py-2" />
          Notwithstanding anything contrary contained in these Delivery Partner
          T&C or the Agreement, Vyloo shall reserve a right to charge You a
          penalty in the event You indulge in fraudulent activities while
          providing Delivery Services. The penalty may be deducted from the
          payments made to You by Vyloo.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            20. MISCELLANEOUS
          </h1>
          <hr className="w-auto py-2" />
          i. Force Majeure: Neither Party shall have any liability under or be
          deemed to be in breach of these Delivery Partner T&C or the Agreement
          for any delays or failures in performance of these Delivery Partner
          T&C and the Agreement which results from circumstances beyond the
          reasonable control of that Party such as acts of god, fire,
          earthquake, tempest, flood, lighting, violence of any army or mob or
          enemies of the country.
          <br />
          <br />
          ii. Assignment: No rights or liabilities under these Delivery Partner
          T&C and the Agreement can be assigned by any of the Parties hereto
          without the prior written consent of the other Party.
          <br />
          <br />
          iii. Entire Agreement: These Delivery Partner T&C shall be read along
          with the Agreement supersedes all prior discussions and agreements
          (whether oral or written) if any, between the Parties with respect to
          the subject matter of these Delivery Partner T&C and the Agreement.
          <br />
          <br />
          iv. Term: These Delivery Partner T&C and the Agreement shall, unless
          specifically terminated in accordance with the provisions contained
          herein, be valid and effective from the date of the execution of the
          Agreement till such time that the Delivery Partner continues to remain
          enlisted with the Vyloo DP Platform.
          <br />
          <br />
          v. Waiver: No waiver of any part of these Delivery Partner T&C and the
          Agreement or consent to any departure from it by any Party shall be
          effective unless it is in writing. A waiver or consent shall be
          effective only for the purpose for which it is given. No default or
          delay on the part of any Party in exercising any rights, powers or
          privileges operates as a waiver of any right, nor does a single or
          partial exercise of a right exclude others.
          <br />
          <br />
          vi. Severance: Any provision of these Delivery Partner T&C and the
          Agreement which is invalid or unenforceable shall be ineffective to
          the extent of such invalidity or unenforceability, without affecting
          in any way the validity, legality and enforceability of the remaining
          provisions hereof.
          <br />
          <br />
          vii. Vyloo retains the right to share the information provided by you
          with any financial lending institution(s) if you agree to the same, if
          it is proved within reason that you have availed a loan from them. You
          forego the right to claim any damages from Vyloo in the event any such
          financial facility has been availed by you. You also agree that Vyloo
          will not be liable for any damages arising as a result of such
          disclosure of your information. Vyloo retains the right to withhold
          pending payouts and terminate you on an immediate basis in such cases.
          <br />
          <br />
          viii. Delivery Partner hereby acknowledges and agrees to the use of
          his/her photographs by Vyloo for certain purposes, which may include
          usage of the Delivery Partner's photographs in Vyloo's internal
          communications and presentations, training materials, marketing and
          advertisement activities on various platforms, including without
          limitation, online advertisement, social media and offline
          advertisement. Delivery Partner hereby consents to sharing of his/her
          photographs by Vyloo with third parties for the aforesaid purposes.
          <br />
          <br />
          ix. The information shared by Delivery Partner pursuant to this
          Agreement shall be used in accordance with the{" "}
          <a href="/privacy" className="underline text-blue-500">
            Privacy Policy
          </a>
          .
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            21. CONFIDENTIALITY
          </h1>
          <hr className="w-auto py-2" />
          Other than for provision of Delivery Services by the Delivery Partner,
          Vyloo does not share any other information of the Delivery Partner
          with third parties unless requisitioned by (i) government authorities
          or (ii) the Delivery Partner, whether orally or in writing (via email,
          SMS etc.) for any purpose whatsoever, including but not limited to
          availing loan from financial institutions, filing of tax returns etc.
          Other than for the purpose of undertaking Delivery Services, the
          Delivery Partner must not disclose any confidential information about
          Vyloo, including but not limited to these Delivery Partner T&C, its
          business strategies, pricing, revenues, expenses, User data, Pharmacy
          Partner data and order information to third parties.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            22. DISCLAIMER
          </h1>
          <hr className="w-auto py-2" />
          Vyloo does not warrant that You will be able to use the Vyloo DP
          Platform and/ or will be able to provide the Delivery Services at all
          times or locations on the Vyloo DP Platform or that the Vyloo DP
          Platform and the Platform Services will be uninterrupted or error-free
          or that the defects will be capable of being corrected by the Vyloo in
          a timely fashion. The Platform Services, Vyloo DP Platform, Device,
          the output generated there from, and all other technology developed by
          Vyloo are provided to you on an “AS IS” and “AS AVAILABLE” basis and
          Vyloo specifically disclaims all warranties and indemnities, express,
          implied or statutory, including without limitation any warranty of
          merchantability, fitness for a particular purpose, accuracy,
          completeness, or any other warranty arising from the course of
          performance or course of dealing
        </p>
        <br />
      </div>
    </>
  );
}
