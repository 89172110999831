import React from "react";
import Sidebar from "./sidebar"; // Ensure the path is correct

const DashboardLayout = ({ children }) => {
  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-grow p-6 bg-gray-100 ml-64">
        {" "}
        {/* Adjust margin-left to match the sidebar width */}
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
