import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import UpdateCustomerRequest from "./UpdateCustomerRequest";
import DashboardLayout from "../dashboardLayout";

const CustomerRequestDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCustomerRequestDetails = async () => {
      setLoading(true);
      setError("");

      try {
        const token = sessionStorage.getItem("authToken");

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getCustomerRequestDetails`,
          {
            customerRequestId: id,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setDetails(response.data.data.customerRequestDetails);
        } else {
          setError("Failed to fetch customer request details.");
        }
      } catch (error) {
        setError("An error occurred while fetching customer request details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerRequestDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!details) return <p>No details found.</p>;

  return (
    <DashboardLayout>
      <>
        <div className="p-6 space-y-8">
          <h1 className="text-4xl font-extrabold mb-6 text-gray-800">
            Customer Request Details
          </h1>

          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-gray-700 mb-6">
              Request Information
            </h2>
            <div className="space-y-4 text-gray-600">
              <p>
                <strong className="text-gray-800">Ticket ID:</strong>{" "}
                {details.ticketId}
              </p>
              <p>
                <strong className="text-gray-800">Status:</strong>{" "}
                {details.status}
              </p>
              <UpdateCustomerRequest
                customerRequestId={details.customerRequestId}
                currentStatus={details.status}
              />
              <p>
                <strong className="text-gray-800">Raised Time:</strong>{" "}
                {new Date(details.raisedTime).toLocaleString()}
              </p>
              <p>
                <strong className="text-gray-800">Description:</strong>{" "}
                {details.description}
              </p>
              <p>
                <strong className="text-gray-800">User ID:</strong>{" "}
                {details.userId}
              </p>

              <Link
                to={`/user-order-history/${details.userId}`}
                className="text-blue-600 hover:underline"
              >
                <button className="mt-2 px-4 py-2 mr-4 font-semibold text-white bg-green-600 rounded-lg hover:bg-green-700 transition duration-200 focus:outline-none focus:shadow-outline">
                  View Order History
                </button>
              </Link>
            </div>

            <h2 className="text-2xl font-semibold text-gray-700 mt-10 mb-4">
              Order Details
            </h2>

            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg overflow-hidden">
                <thead>
                  <tr className="bg-gray-800 text-white text-left">
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      Receiver Name
                    </th>
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      Receiver Phone Number
                    </th>
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      Address Line 1
                    </th>
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      Address Line 2
                    </th>
                    <th className="py-3 px-4 uppercase font-semibold text-sm">
                      Payment Method
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600">
                  {details.orderDetails.length > 0 ? (
                    details.orderDetails.map((order) => (
                      <tr
                        key={order._id}
                        className="hover:bg-gray-100 transition duration-200"
                      >
                        <td className="py-3 px-4 border-b border-gray-200">
                          {order.receiverName}
                        </td>
                        <td className="py-3 px-4 border-b border-gray-200">
                          {order.receiverPhoneNumber}
                        </td>
                        <td className="py-3 px-4 border-b border-gray-200">
                          {order.addressLine1}
                        </td>
                        <td className="py-3 px-4 border-b border-gray-200">
                          {order.addressLine2}
                        </td>
                        <td className="py-3 px-4 border-b border-gray-200">
                          {order.paymentMethod}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="py-3 px-4 text-center" colSpan="5">
                        No order details found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default CustomerRequestDetails;
