import React, { useState } from "react";
import axios from "axios";
import DashboardLayout from "./dashboardLayout";

const AddProduct = () => {
  const [productData, setProductData] = useState({
    type: "",
    uniqueId: "",
    url: "",
    displayName: "",
    subCategoryId: [],
    categoryId: [],
    keyIngredients: "",
    safetyAdviceType: "",
    safetyAdviceTag: "",
    safetyStatement: "",
    benefits: "",
    howToUse: "",
    productDescription: "",
    chemicalClass: "",
    habitForming: "",
    uses: "",
    mrp: "",
    productIntroduction: "",
    prescriptionRequired: "",
    packaging: "",
    manufacturer: "",
    ifMissedDose: "",
    sideEffects: "",
    storage: "",
    faqQuestion: "",
    faqAnswer: "",
    packageType: "",
    productForm: "",
  });

  const [responseMsg, setResponseMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    const payload = {
      type: productData.type,
      uniqueId: productData.uniqueId,
      url: productData.url,
      displayName: productData.displayName,
      //subCategoryId: productData.subCategoryId
      //.split(",")
      // .map((id) => id.trim()),
      //categoryId: productData.categoryId.split(",").map((id) => id.trim()),
      keyIngredients: productData.keyIngredients
        .split(",")
        .map((ingredient) => ingredient.trim()),
      safetyAdvice: [
        {
          type: productData.safetyAdviceType,
          safetyAdviceTag: productData.safetyAdviceTag,
          safetyStatement: productData.safetyStatement,
        },
      ],
      benefits: productData.benefits,
      howToUse: productData.howToUse,
      productDescription: productData.productDescription,
      factBox: [
        { key: "Chemical Class", value: productData.chemicalClass },
        { key: "Habit Forming", value: productData.habitForming },
      ],
      uses: productData.uses.split(",").map((use) => use.trim()),
      mrp: productData.mrp,
      productIntroduction: productData.productIntroduction,
      prescriptionRequired: productData.prescriptionRequired,
      packaging: productData.packaging,
      manufacturer: productData.manufacturer,
      ifMissedDose: productData.ifMissedDose,
      sideEffects: productData.sideEffects
        .split(",")
        .map((effect) => effect.trim()),
      storage: productData.storage,
      faqs: [
        {
          question: productData.faqQuestion,
          answer: productData.faqAnswer,
        },
      ],
      packageType: productData.packageType,
      productForm: productData.productForm,
    };

    try {
      const authToken = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/addProductsByAdmin`,
        payload,
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (response.data.success) {
        setResponseMsg("Product added successfully!");
      } else {
        setResponseMsg("Failed to add product.");
      }
    } catch (error) {
      setResponseMsg("Error: " + error.message);
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="flex flex-col items-center justify-center p-6 bg-gray-100 min-h-screen">
          <h1 className="text-2xl font-bold mb-6">Add New Product</h1>
          <form
            className="grid grid-cols-1 gap-4 w-full max-w-lg"
            onSubmit={handleAddProduct}
          >
            <input
              type="text"
              name="type"
              placeholder="Type (e.g., medicines)"
              value={productData.type}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="uniqueId"
              placeholder="Unique ID"
              value={productData.uniqueId}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="url"
              placeholder="Product URL"
              value={productData.url}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="displayName"
              placeholder="Display Name"
              value={productData.displayName}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            {/* <input
          type="text"
          name="subCategoryId"
          placeholder="Sub Category ID (comma separated)"
          value={productData.subCategoryId}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="categoryId"
          placeholder="Category ID (comma separated)"
          value={productData.categoryId}
          onChange={handleChange}
          className="p-2 border border-gray-300 rounded"
        /> */}
            <input
              type="text"
              name="keyIngredients"
              placeholder="Key Ingredients (Please add comma separated values)"
              value={productData.keyIngredients}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="safetyAdviceType"
              placeholder="Safety Advice Type"
              value={productData.safetyAdviceType}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="safetyAdviceTag"
              placeholder="Safety Advice Tag"
              value={productData.safetyAdviceTag}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="safetyStatement"
              placeholder="Safety Statement"
              value={productData.safetyStatement}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <textarea
              name="benefits"
              placeholder="Benefits"
              value={productData.benefits}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <textarea
              name="howToUse"
              placeholder="How to Use"
              value={productData.howToUse}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <textarea
              name="productDescription"
              placeholder="Product Description"
              value={productData.productDescription}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="chemicalClass"
              placeholder="Chemical Class"
              value={productData.chemicalClass}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="habitForming"
              placeholder="Habit Forming"
              value={productData.habitForming}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="uses"
              placeholder="Uses (Please add comma separated values)"
              value={productData.uses}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="mrp"
              placeholder="MRP"
              value={productData.mrp}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            <textarea
              name="productIntroduction"
              placeholder="Product Introduction"
              value={productData.productIntroduction}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="prescriptionRequired"
              placeholder="Prescription Required (Yes/No)"
              value={productData.prescriptionRequired}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="packaging"
              placeholder="Packaging"
              value={productData.packaging}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            <input
              type="text"
              name="manufacturer"
              placeholder="Manufacturer"
              value={productData.manufacturer}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              required
            />
            <textarea
              name="ifMissedDose"
              placeholder="If Missed Dose"
              value={productData.ifMissedDose}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="sideEffects"
              placeholder="Side Effects (Please add comma separated values)"
              value={productData.sideEffects}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="storage"
              placeholder="Storage"
              value={productData.storage}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="faqQuestion"
              placeholder="FAQ Question"
              value={productData.faqQuestion}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="faqAnswer"
              placeholder="FAQ Answer"
              value={productData.faqAnswer}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
            />
            <input
              type="text"
              name="packageType"
              placeholder="Package Type"
              value={productData.packageType}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <input
              type="text"
              name="productForm"
              placeholder="Product Form"
              value={productData.productForm}
              onChange={handleChange}
              className="p-2 border border-gray-300 rounded"
              //required
            />
            <button
              type="submit"
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Add Product
            </button>
          </form>
          {responseMsg && (
            <div className="mt-4 text-lg text-green-600">{responseMsg}</div>
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default AddProduct;
