import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import DashboardLayout from "./dashboardLayout";

const SupportRequests = () => {
  const [supportRequests, setSupportRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSupportRequests = async () => {
      setLoading(true);
      setError("");

      try {
        const token = sessionStorage.getItem("authToken");
        console.log(token);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllSupportRequests`,
          {
            filterKey: "all",
            page: 1,
            size: 100,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setSupportRequests(response.data.data.allRequests);
        } else {
          setError("Failed to fetch support requests.");
        }
      } catch (error) {
        setError("An error occurred while fetching support requests.");
      } finally {
        setLoading(false);
      }
    };

    fetchSupportRequests();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <DashboardLayout>
      <>
        <div className="p-6 min-h-screen">
          <h1 className="text-3xl font-bold mb-6 text-gray-800">
            Support Requests
          </h1>
          <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-200">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-gray-800 text-white">
                  <th className="text-left py-3 px-6 uppercase font-semibold text-sm">
                    Ticket ID
                  </th>
                  <th className="text-left py-3 px-6 uppercase font-semibold text-sm">
                    Order ID
                  </th>
                  <th className="text-left py-3 px-6 uppercase font-semibold text-sm">
                    Status
                  </th>
                  <th className="text-left py-3 px-6 uppercase font-semibold text-sm">
                    Receiver Name
                  </th>
                  <th className="text-left py-3 px-6 uppercase font-semibold text-sm">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {supportRequests.length > 0 ? (
                  supportRequests.map((request) => (
                    <tr
                      key={request._id}
                      className="hover:bg-gray-100 transition-colors duration-200"
                    >
                      <td className="py-4 px-6 border-b border-gray-200">
                        {request.ticketId}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-200">
                        {request.orderId}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-200">
                        <span
                          className={`px-3 py-1 inline-flex leading-5 font-semibold rounded-full ${
                            request.status === "closed"
                              ? "bg-green-100 text-green-800"
                              : request.status === "pending"
                              ? "bg-yellow-100 text-yellow-800"
                              : request.status === "invalid"
                              ? "bg-blue-100 text-blue-800"
                              : request.status === "open"
                              ? "bg-orange-100 text-orange-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {request.status}
                        </span>
                      </td>
                      <td className="py-4 px-6 border-b border-gray-200">
                        {request.receiverName}
                      </td>
                      <td className="py-4 px-6 border-b border-gray-200">
                        <Link
                          to={`/admin/customer-request/${request._id}`}
                          className="text-blue-500 hover:underline"
                        >
                          <button className="px-4 py-2 font-bold text-white bg-green-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline transition duration-200">
                            View Details
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      className="py-4 px-6 text-center text-gray-500"
                      colSpan="5"
                    >
                      No support requests found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default SupportRequests;
