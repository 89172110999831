import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "./dashboardLayout";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      setError("");

      try {
        const token = sessionStorage.getItem("authToken");
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllActiveCategories`,
          {},
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          // Flattening the nested categories arrays into a single array
          const flattenedCategories = response.data.data.categories.flatMap(
            (categoryGroup) => categoryGroup.categories
          );
          setCategories(flattenedCategories);
        } else {
          setError("Failed to fetch categories.");
        }
      } catch (error) {
        setError("An error occurred while fetching categories.");
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/admin/subcategories/${categoryId}`);
  };

  return (
    <DashboardLayout>
      <>
        <div className="p-6 bg-gray-100 min-h-screen">
          <h1 className="text-3xl font-bold mb-6 text-gray-800">Categories</h1>

          {loading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {/* Skeleton Loader for Loading State */}
              {[...Array(8)].map((_, index) => (
                <div
                  key={index}
                  className="animate-pulse bg-white shadow-md rounded-lg p-4"
                >
                  <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
                  <div className="h-4 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-24 bg-gray-300 rounded-lg"></div>
                </div>
              ))}
            </div>
          ) : error ? (
            <p className="text-red-500 text-lg font-semibold">{error}</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {categories.map((category) => (
                <div
                  key={category._id}
                  className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between cursor-pointer hover:shadow-lg transition-shadow duration-300"
                  onClick={() => handleCategoryClick(category._id)}
                >
                  <div>
                    <h2 className="text-xl font-bold text-gray-900 mb-2">
                      {category.displayName}
                    </h2>
                    <p className="text-gray-500 text-sm mb-4">
                      Id: {category._id}
                    </p>
                  </div>

                  {category.imageUrl ? (
                    <img
                      src={`https://d2l55457kk8x11.cloudfront.net/${category.imageUrl}`}
                      alt={category.displayName}
                      className="mt-4 w-24 h-24 object-cover rounded-lg mx-auto"
                    />
                  ) : (
                    <div className="mt-4 w-24 h-24 bg-gray-200 rounded-lg mx-auto flex items-center justify-center">
                      <span className="text-gray-400">No Image</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    </DashboardLayout>
  );
};

export default Categories;
