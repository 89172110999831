import { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "./dashboardLayout";

const EditProductModal = ({
  isOpen,
  onClose,
  productDetails,
  onChange,
  onSave,
  originalImage,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg w-96 shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Edit Product</h2>
        {originalImage && (
          <img
            src={originalImage}
            alt={productDetails.displayName}
            className="w-32 h-32 object-cover rounded-lg mx-auto mb-4"
          />
        )}
        <div className="mb-4">
          <label
            htmlFor="displayName"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Display Name
          </label>
          <input
            id="displayName"
            type="text"
            name="displayName"
            value={productDetails.displayName}
            onChange={onChange}
            placeholder="Enter display name"
            className="p-3 border rounded-lg w-full text-gray-900"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="mrp"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            MRP
          </label>
          <input
            id="mrp"
            type="text"
            name="mrp"
            value={productDetails.mrp}
            onChange={onChange}
            placeholder="Enter MRP"
            className="p-3 border rounded-lg w-full text-gray-900"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="packaging"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Packaging
          </label>
          <input
            id="packaging"
            type="text"
            name="packaging"
            value={productDetails.packaging}
            onChange={onChange}
            placeholder="Enter packaging"
            className="p-3 border rounded-lg w-full text-gray-900"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="packageType"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Package Type
          </label>
          <input
            id="packageType"
            type="text"
            name="packageType"
            value={productDetails.packageType}
            onChange={onChange}
            placeholder="Enter package type"
            className="p-3 border rounded-lg w-full text-gray-900"
          />
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="bg-red-100 text-red-700 p-3 rounded-lg mr-2 hover:bg-red-700 hover:text-red-100"
          >
            Cancel
          </button>
          <button
            onClick={onSave}
            className="bg-secondary-color text-white p-3 rounded-lg hover:bg-primary-color"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const Medicines = () => {
  const [medicines, setMedicines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [productToAdd, setProductToAdd] = useState(null);
  const [showDropdown, setShowDropdown] = useState(null);
  const [updatingProductIds, setUpdatingProductIds] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [updatedProductDetails, setUpdatedProductDetails] = useState({
    displayName: "",
    mrp: "",
    packaging: "",
    packageType: "",
  });
  const [originalImage, setOriginalImage] = useState("");
  const [filter, setFilter] = useState("all");
  const adminRole = sessionStorage.getItem("adminRole");
  const getAdminRole = sessionStorage.getItem("adminRole");
  const fetchMedicines = async () => {
    setLoading(true);
    setError("");

    try {
      const token = sessionStorage.getItem("authToken");
      let url = `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllProducts`;

      // Modify URL based on filter
      if (filter === "active") {
        url = `${url}?status=active`;
      } else if (filter === "inactive") {
        url = `${url}?status=inactive`;
      }

      let params = {
        page,
        size: 12,
        productType: "medicines",
        query: searchQuery,
      };

      const response = await axios.post(url, params, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data.success) {
        const productsData = response.data.data.products.flatMap(
          (productGroup) => productGroup.products
        );
        setMedicines(productsData);
      } else {
        setError("Failed to fetch medicines.");
      }
    } catch (error) {
      setError("An error occurred while fetching medicines.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMedicines();
  }, [page, searchQuery, filter]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = sessionStorage.getItem("authToken");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllActiveCategories`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.data.success) {
          setCategories(response.data.data.categories);
        } else {
          setError("Failed to fetch categories.");
        }
      } catch (error) {
        setError("An error occurred while fetching categories.");
      }
    };

    fetchCategories();
  }, []);

  const handlePageChange = (direction) => {
    if (direction === "next") {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setPage(1);
  };

  const handleCategoryDropdown = (productId) => {
    setShowDropdown((prev) => (prev === productId ? null : productId));
  };

  const handleCategorySelect = async (categoryId) => {
    setSelectedCategoryId(categoryId);
    setSubCategories([]);

    if (categoryId) {
      try {
        const token = sessionStorage.getItem("authToken");
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getAllActiveSubCategoriesOfCategory`,
          {
            categoryId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (response.data.success) {
          setSubCategories(response.data.data.subCategories);
        } else {
          setError("Failed to fetch subcategories.");
        }
      } catch (error) {
        setError("An error occurred while fetching subcategories.");
      }
    }
  };

  const handleSubCategorySelect = (subCategoryId, productId) => {
    setSelectedSubCategoryId(subCategoryId);
    setProductToAdd(productId);
  };

  const handleAddToSubCategory = async () => {
    if (!productToAdd || !selectedSubCategoryId) return;

    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/addProductToSubCategory`,
        {
          productId: productToAdd,
          subCategoryId: selectedSubCategoryId,
          productType: "medicines",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        alert("Product added to subcategory successfully!");
        setSelectedCategoryId(null);
        setSelectedSubCategoryId(null);
        setProductToAdd(null);
        setShowDropdown(null);
      } else {
        setError("Failed to add product to subcategory.");
      }
    } catch (error) {
      setError("An error occurred while adding product to subcategory.");
    }
  };

  const handleStatusToggle = async (productId, currentActiveStatus) => {
    const desiredFlag = currentActiveStatus;

    setUpdatingProductIds((prev) => [...prev, productId]);

    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/toggleProductAvailability`,
        {
          productId,
          productType: "medicines",
          flag: desiredFlag,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        setMedicines((prevMedicines) =>
          prevMedicines.map((medicine) =>
            medicine._id === productId
              ? { ...medicine, active: desiredFlag }
              : medicine
          )
        );
        alert("Product activated/deactivated successfully");
      } else {
        alert("Failed to update product status.");
      }
    } catch (error) {
      alert("An error occurred while updating product status.");
    } finally {
      setUpdatingProductIds((prev) => prev.filter((id) => id !== productId));
    }
  };

  const handleEditClick = (medicine) => {
    setEditingProduct(medicine._id);
    setUpdatedProductDetails({
      displayName: medicine.displayName,
      mrp: medicine.mrp,
      packaging: medicine.packaging,
      packageType: medicine.packageType,
    });
    setOriginalImage(
      `https://d2l55457kk8x11.cloudfront.net/${medicine.imageUrl}`
    );
  };

  const handleProductDetailsChange = (event) => {
    const { name, value } = event.target;
    setUpdatedProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateProduct = async () => {
    if (!editingProduct) return;

    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/${adminRole}/updateProductDetails`,
        {
          productId: editingProduct,
          productType: "medicines",
          update: updatedProductDetails,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        alert("Product details updated successfully!");
        setMedicines((prevMedicines) =>
          prevMedicines.map((medicine) =>
            medicine._id === editingProduct
              ? { ...medicine, ...updatedProductDetails }
              : medicine
          )
        );
        setEditingProduct(null);
      } else {
        setError("Failed to update product details.");
      }
    } catch (error) {
      setError("An error occurred while updating product details.");
    }
  };

  const filteredMedicines = medicines.filter((otc) => {
    if (filter === "all") return true;
    if (filter === "active") return otc.active;
    if (filter === "inactive") return !otc.active;
    return true;
  });

  return (
    <DashboardLayout>
      <>
        <div className="p-6 bg-gray-100">
          <h1 className="text-2xl font-bold mb-4">Medicines</h1>

          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search for a medicine..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="p-3 pl-10 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all w-full"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-3 top-3 w-5 h-5 text-gray-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
            {searchQuery && (
              <button
                onClick={() => setSearchQuery("")}
                className="absolute right-3 top-3 text-gray-500"
              >
                &times;
              </button>
            )}
          </div>
          <div className="mb-4">
            <button
              onClick={() => setFilter("all")}
              className={`px-4 py-2 rounded-md mr-2 ${
                filter === "all" ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              All
            </button>
            <button
              onClick={() => setFilter("active")}
              className={`px-4 py-2 rounded-md mr-2 ${
                filter === "active" ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              Active
            </button>
            <button
              onClick={() => setFilter("inactive")}
              className={`px-4 py-2 rounded-md ${
                filter === "inactive" ? "bg-blue-500 text-white" : "bg-gray-300"
              }`}
            >
              Inactive
            </button>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : medicines.length === 0 ? (
            <p>No medicines found.</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
              {filteredMedicines.map((medicine) => (
                <div
                  key={medicine._id}
                  className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300"
                >
                  <div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      {medicine.displayName}
                    </h2>
                    <p className="text-gray-600 mt-1">
                      Packaging:{" "}
                      <span className="font-medium">{medicine.packaging}</span>
                    </p>
                    <p className="text-gray-600 mt-1">
                      Manufacturer:{" "}
                      <span className="font-medium">
                        {medicine.manufacturer}
                      </span>
                    </p>
                    <p className="text-gray-800 mt-2 text-lg font-semibold">
                      MRP: ₹{medicine.mrp}
                    </p>
                    {getAdminRole === "masteradmin" && (
                      <div className="mt-3 flex items-center space-x-4">
                        <span
                          className={`font-semibold text-lg ${
                            medicine.active ? "text-green-700" : "text-red-700"
                          }`}
                        >
                          Status: {medicine.active ? "Active" : "Inactive"}
                        </span>
                        <label className="inline-flex items-center cursor-pointer relative">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={medicine.active}
                            onChange={() =>
                              handleStatusToggle(medicine._id, medicine.active)
                            }
                            disabled={updatingProductIds.includes(medicine._id)}
                          />
                          <div className="w-11 h-6 bg-red-500 rounded-full peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 peer-checked:bg-green-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-transform"></div>
                          {updatingProductIds.includes(medicine._id) && (
                            <span className="ml-3 text-sm text-gray-600 animate-pulse">
                              Updating...
                            </span>
                          )}
                        </label>
                      </div>
                    )}
                  </div>

                  {medicine.imageUrl && (
                    <img
                      src={`https://d2l55457kk8x11.cloudfront.net/${medicine.imageUrl}`}
                      alt={medicine.displayName}
                      className="p-4 mt-4 w-28 h-28 object-cover rounded-lg mx-auto border border-gray-200"
                    />
                  )}
                  {getAdminRole === "masteradmin" && (
                    <div className="mt-4 space-y-2">
                      <button
                        className="bg-secondary-color text-white p-3 rounded-lg w-full hover:bg-primary-color transition-colors"
                        onClick={() => handleCategoryDropdown(medicine._id)}
                      >
                        Add to Subcategory
                      </button>

                      {showDropdown === medicine._id && (
                        <div>
                          <select
                            className="p-3 border border-gray-300 rounded-lg w-full"
                            onChange={(e) =>
                              handleCategorySelect(e.target.value)
                            }
                            value={selectedCategoryId || ""}
                          >
                            <option value="">Select Category</option>
                            {categories.map((category) =>
                              category.categories.map((subCategory) => (
                                <option
                                  key={subCategory._id}
                                  value={subCategory._id}
                                >
                                  {subCategory.displayName}
                                </option>
                              ))
                            )}
                          </select>

                          {subCategories.length > 0 && (
                            <select
                              className="mt-2 mb-2 p-3 border border-gray-300 rounded-lg w-full"
                              onChange={(e) =>
                                handleSubCategorySelect(
                                  e.target.value,
                                  medicine._id
                                )
                              }
                              value={selectedSubCategoryId || ""}
                            >
                              <option value="">Select Subcategory</option>
                              {subCategories.map((subCategory) => (
                                <option
                                  key={subCategory._id}
                                  value={subCategory._id}
                                >
                                  {subCategory.displayName}
                                </option>
                              ))}
                            </select>
                          )}

                          <button
                            className="bg-green-500 text-white p-3 mt-2 rounded-lg w-full hover:bg-green-600 transition-colors"
                            onClick={handleAddToSubCategory}
                          >
                            Confirm Add
                          </button>
                        </div>
                      )}

                      {adminRole === "masteradmin" && (
                        <button
                          onClick={() => handleEditClick(medicine)}
                          className="bg-gray-200 text-gray-800 p-3 rounded-lg w-full border border-gray-300 hover:bg-gray-300 transition-colors"
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          <div className="flex items-center justify-center mt-12">
            <button
              onClick={() => handlePageChange("previous")}
              disabled={page === 1}
              className={`p-2 border rounded-l-lg ${
                page === 1
                  ? "bg-gray-300 text-gray-600"
                  : "bg-gray-200 hover:bg-gray-300"
              } transition-colors`}
            >
              &lt; Previous
            </button>
            <span className="px-4 py-2 text-gray-700 font-semibold">
              Page {page}
            </span>
            <button
              onClick={() => handlePageChange("next")}
              className="p-2 border rounded-r-lg bg-gray-200 hover:bg-gray-300 transition-colors"
            >
              Next &gt;
            </button>
          </div>
        </div>
        <EditProductModal
          isOpen={!!editingProduct}
          onClose={() => setEditingProduct(null)}
          productDetails={updatedProductDetails}
          onChange={handleProductDetailsChange}
          onSave={handleUpdateProduct}
          originalImage={originalImage}
        />
      </>
    </DashboardLayout>
  );
};

export default Medicines;
