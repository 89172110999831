import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DashboardLayout from "./dashboardLayout";

const Products = () => {
  const { subCategoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);

  useEffect(() => {
    const productType = localStorage.getItem("productType");

    const fetchProducts = async () => {
      setLoading(true);
      setError("");

      try {
        const token = sessionStorage.getItem("authToken");
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/getProductListOfSubCategory`,
          {
            page, // <-- Use dynamic page state here
            size: 12, // Adjust size if needed
            subCategoryId,
            productType,
            query: searchQuery,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          const fetchedProducts = response.data.data.products[0].products;

          // If the number of fetched products is less than the page size, disable "Next" button
          setHasMoreProducts(fetchedProducts.length === 12);
          setProducts(fetchedProducts);
        } else {
          setError("Failed to fetch products.");
        }
      } catch (error) {
        setError("An error occurred while fetching products.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [subCategoryId, page, searchQuery]); // Ensure `page` is a dependency to re-fetch products on page change

  const handlePageChange = (direction) => {
    if (direction === "next") {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setPage(1); // Reset to page 1 when searching
  };

  const handleDeleteProduct = async (productId) => {
    const productType = localStorage.getItem("productType");
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/removeProductFromSubCategory`,
        {
          productId,
          subCategoryId,
          productType,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        setProducts(products.filter((product) => product._id !== productId));
        alert("Product deleted successfully");
      } else {
        setError("Failed to delete product.");
      }
    } catch (error) {
      setError("An error occurred while deleting the product.");
    }
  };

  return (
    <DashboardLayout>
      <>
        <div className="p-6 bg-gray-100">
          <h1 className="text-2xl font-bold mb-4">Products</h1>
          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search for a medicine..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="p-3 pl-10 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all w-full"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-3 top-3 w-5 h-5 text-gray-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8" />
              <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
            {searchQuery && (
              <button
                onClick={() => setSearchQuery("")}
                className="absolute right-3 top-3 text-gray-500"
              >
                &times;
              </button>
            )}
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {products.map((product) => (
                  <div
                    key={product._id}
                    className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow duration-300 flex flex-col justify-between"
                  >
                    <div>
                      <h3 className="text-xl font-bold text-gray-900">
                        {product.displayName}
                      </h3>
                      <p className="text-gray-500 mt-2">MRP: ₹{product.mrp}</p>
                      <p className="text-gray-500 mt-2">{product.packaging}</p>
                      <p className="text-gray-500 mt-2">
                        Manufacturer: {product.manufacturer}
                      </p>
                    </div>

                    {product.imageUrl ? (
                      <img
                        src={`https://d2l55457kk8x11.cloudfront.net/${product.imageUrl}`}
                        alt={product.displayName}
                        className="mt-4 w-24 h-24 object-cover rounded-lg mx-auto"
                      />
                    ) : (
                      <div className="mt-4 w-24 h-24 bg-gray-200 rounded-lg mx-auto flex items-center justify-center">
                        <span className="text-gray-400">No Image</span>
                      </div>
                    )}

                    <button
                      className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-300"
                      onClick={() => handleDeleteProduct(product._id)}
                    >
                      Delete Product
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex justify-center space-x-4 mt-6">
                <button
                  onClick={() => handlePageChange("previous")}
                  className={`px-4 py-2 rounded ${
                    page === 1
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300"
                  }`}
                  disabled={page === 1}
                >
                  Previous
                </button>
                <span className="px-4 py-2 rounded bg-gray-100 text-gray-800">
                  {page}
                </span>
                <button
                  onClick={() => handlePageChange("next")}
                  className={`px-4 py-2 rounded ${
                    hasMoreProducts
                      ? "bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  disabled={!hasMoreProducts}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </>
    </DashboardLayout>
  );
};

export default Products;
