import { React, useState } from "react";
import Navbar from "../components/navbar";
import axios from "axios";

const ReferralCodeGenerator = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [upiId, setUpiId] = useState("");
  const [name, setName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(false);

  const generateReferralCode = async () => {
    setLoading(true);
    setError("");

    try {
      const payload = {
        mobileNumber: Number(mobileNumber),
        upiId,
        name,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/app/generateUserReferralCode`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        setReferralCode(response.data.data.referralCode);
      } else {
        setError(response.data.message || "Failed to generate referral code");
      }
    } catch (err) {
      console.log(err.response);
      setError(err.response?.data?.message || "Error generating referral code");
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
    setCopied(true);
  };

  return (
    <>
      <Navbar />
      <h1 className="text-4xl text-center font-bold text-[#16496F] py-1">
        Vyloo Referral Program
      </h1>
      <br />
      <div className="">
        <div className="gap-8 md:gap-[150px] grid grid-cols-1 lg:grid-cols-2 py-4 px-6 md:px-16 lg:px-40">
          <div className="p-4 md:p-8 lg:text-left text-center">
            <h1 className="text-2xl font-black text-[#16496F] py-1">
              Terms and Conditions for Vyloo Referral Program
            </h1>

            <p className="py-4 text-md text-[#1c1e21ad]">
              Welcome to the Vyloo Referral Program ("Program"). These terms and
              conditions ("Terms") govern your participation in the Program. By
              participating in the Program, you agree to be bound by these
              Terms:
            </p>
          </div>
          <div className="w-full max-w-md p-6 md:p-8 bg-white shadow-md rounded-lg mx-auto">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
              Generate Referral Code
            </h2>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Mobile Number
              </label>
              <input
                type="text"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
                className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="Enter mobile number"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-1">
                UPI ID
              </label>
              <input
                type="text"
                value={upiId}
                required
                onChange={(e) => setUpiId(e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="Enter UPI ID"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Name
              </label>
              <input
                type="text"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder="Enter your name"
              />
            </div>

            <button
              onClick={generateReferralCode}
              className={`w-full p-3 text-white font-semibold rounded-md transition ${
                loading
                  ? "bg-blue-400 cursor-not-allowed"
                  : "bg-primary-color hover:bg-secondary-color"
              }`}
              disabled={loading}
            >
              {loading ? "Generating..." : "Generate Referral Code"}
            </button>

            {error && (
              <div className="mt-4 text-center text-red-600 font-medium">
                {error}
              </div>
            )}

            {referralCode && (
              <div className="mt-6 text-center">
                <h3 className="text-xl font-semibold text-gray-800">
                  Referral Code:
                </h3>
                <div className="flex justify-center items-center gap-4 mt-2">
                  <p className="text-3xl font-bold text-primary-color">
                    {referralCode}
                  </p>
                  <button
                    onClick={copyToClipboard}
                    className="px-4 py-2 text-white bg-secondary-color rounded-md focus:outline-none hover:bg-secondary-hover-color"
                  >
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-8 lg:px-28 text-justify">
        {/* <h1 className="text-2xl font-black text-[#16496F] py-1">
          Terms and Conditions for Vyloo Referral Program
        </h1> */}

        <p className="py-4 text-md text-[#1c1e21ad]">
          {/* Welcome to the Vyloo Referral Program ("Program"). These terms and
          conditions ("Terms") govern your participation in the Program. By
          participating in the Program, you agree to be bound by these Terms: */}
          {/* <br /> <br /> */}
          <h1 className="text-xl font-black text-[#16496F] py-1">
            1. Eligibility to Participate
          </h1>{" "}
          - The Program is open to Vyloo users who have an active Vyloo account.
          <br />
          - Only individuals referring new users who have not previously
          registered on Vyloo are eligible to participate.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            2. Referral Process
          </h1>{" "}
          - To participate, you must share your unique referral link or code
          with friends, family, or colleagues.
          <br />
          - The referred person ("Referred User") must sign up on Vyloo using
          your referral link or code and place their first order to complete the
          referral.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            3. Referral Rewards
          </h1>
          - <strong>*For Referrer*</strong>: Upon successful completion of the
          Referred User's first order, you, the Referrer, will receive ₹500
          cashback directly into your linked bank account. <br />- *For Referred
          User*: The Referred User will receive a ₹50 in-app discount, which can
          be applied on their first purchase.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            4. Conditions for Receiving Cashback and Discount
          </h1>
          - The Referrer will receive the cashback only if the Referred User
          successfully completes their first order on Vyloo. <br />- Cashback
          for the Referrer will be credited directly to the bank account linked
          with their Vyloo profile within 7 business days after the Referred
          User's first successful order.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            5. Exclusions
          </h1>
          - Referrals will not be eligible for rewards if the Referred User does
          not place an order or cancels their order. <br />- Cashback will not
          be awarded if the Referred User was already referred by another user
          or is using the same payment details as the Referrer.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            6. Program Abuse
          </h1>
          - Any attempts to abuse or manipulate the Program, including but not
          limited to creating fake accounts, will result in disqualification
          from the Program and forfeiture of any earned rewards. <br />- Vyloo
          reserves the right to cancel, suspend, or modify the Program at any
          time without notice if we detect fraud, abuse, or any violation of
          these Terms.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            7. Program Duration
          </h1>
          - Vyloo reserves the right to terminate the Program at any time
          without prior notice.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            8. Limitation of Liability
          </h1>
          - Vyloo is not responsible for any issues related to the
          non-availability of cashback or discount rewards due to technical,
          network, or other unforeseeable issues.
          <br />
          <br />
          <h1 className="text-xl font-black text-[#16496F] py-1">
            9. Modification of Terms
          </h1>
          - Vyloo reserves the right to modify these Terms at any time, with or
          without prior notice. Continued participation in the Program
          constitutes your acceptance of the revised Terms.
          <br />
          By participating in the Vyloo Referral Program, you acknowledge that
          you have read, understood, and agreed to these Terms and Conditions.
        </p>
        <br />
        <br />
      </div>
    </>
  );
};

export default ReferralCodeGenerator;
