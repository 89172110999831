import React, { useState } from "react";
import axios from "axios";

const OrderDetailsSearch = () => {
  const [orderNumericId, setOrderNumericId] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchOrderDetails = async () => {
    if (!orderNumericId) {
      setError("Please enter a valid Order ID");
      return;
    }

    setLoading(true);
    setError("");
    setOrderDetails(null); // Clear previous results on new search

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/admin/getOrderDetails`,
        { orderNumericId },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data.success) {
        setOrderDetails(response.data.data.orderDetails[0].order);
      } else {
        setError("Failed to fetch order details.");
      }
    } catch (error) {
      setError("An error occurred while fetching the order details.");
    } finally {
      setLoading(false);
    }
  };

  const clearSearch = () => {
    setOrderNumericId("");
    setError("");
    setOrderDetails(null);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  return (
    <div className="p-2">
      <div className="flex gap-2 items-center">
        <div className="relative w-full">
          <input
            type="text"
            value={orderNumericId}
            onChange={(e) => setOrderNumericId(e.target.value)}
            placeholder="Search Order ID"
            className="p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-400 pr-10"
          />
          {orderNumericId && (
            <button
              onClick={clearSearch}
              className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          )}
        </div>
        <button
          onClick={fetchOrderDetails}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
        >
          Search
        </button>
      </div>

      {loading && <p className="text-blue-600">Loading...</p>}
      {error && <p className="text-red-600 font-semibold">{error}</p>}

      {orderDetails && (
        <div className="bg-white shadow rounded p-4 text-sm max-w-lg mt-4">
          <h2 className="text-lg font-semibold mb-2">
            Order ID: {orderDetails.orderId}
          </h2>
          <p>
            <strong>User:</strong> {orderDetails.userName}
          </p>
          <p>
            <strong>Merchant:</strong> {orderDetails.merchantShopName}
          </p>
          <p>
            <strong>Status:</strong> {orderDetails.status}
          </p>
          <p>
            <strong>Payment Status:</strong> {orderDetails.paymentStatus}
          </p>
          <p>
            <strong>Total Amount:</strong> ₹{orderDetails.totalAmount}
          </p>
          <p>
            <strong>Grand Total:</strong> ₹{orderDetails.grandTotal}
          </p>
          <p>
            <strong>Order Placed:</strong>{" "}
            {formatDate(orderDetails.orderPlacedTime)}
          </p>
          <p>
            <strong>Delivery:</strong>{" "}
            {orderDetails.isDelivered ? "Delivered" : "Not Delivered"}
          </p>

          <h3 className="text-md font-semibold mt-4">Order Items</h3>
          {orderDetails.orderItems.map((item) => (
            <div key={item._id} className="border-t py-2 text-gray-700">
              <p>
                <strong>Name:</strong> {item.displayName}
              </p>
              <p>
                <strong>Quantity:</strong> {item.quantity}
              </p>
              <p>
                <strong>Price:</strong> ₹{item.price}
              </p>
              <p>
                <strong>Selling Price:</strong> ₹{item.sellingPrice}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderDetailsSearch;
