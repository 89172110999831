import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import adminLoginImg from "../assets/adminlogin.png";

const AdminLogin = ({ setAdminData }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/adminLogin`,
        {
          email,
          password,
        }
      );

      if (response.data.success) {
        const authToken = response.data.data.adminAuthToken;
        const adminRole = response.data.data.adminRole;
        const adminName = response.data.data.adminName;
        sessionStorage.setItem("authToken", authToken);
        sessionStorage.setItem("adminRole", adminRole);
        sessionStorage.setItem("adminName", adminName);

        navigate("/admin/dashboard");
      } else {
        setError("Invalid email or password.");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError("Invalid credentials. Please try again.");
      } else if (err.response && err.response.status === 429) {
        setError("Too Many Requests. Please try later.");
      } else {
        setError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="h-screen flex">
      <div className="w-1/2 flex items-center justify-center relative">
        <div className="absolute inset-0 bg-opacity-50">
          <img
            src={adminLoginImg}
            alt="Background"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="text-white text-center p-8 z-10">
          <h1 className="text-5xl font-bold mb-4">Vyloo Admin Panel</h1>
          <p className="text-lg font-light mb-32">
            Log in to access your dashboard and manage your orders.
          </p>
        </div>
      </div>

      <div className="w-1/2 flex items-center justify-center bg-gray-100">
        <div className="bg-white p-10 shadow-2xl rounded-lg max-w-md w-full">
          <h2 className="text-3xl font-bold mb-6 text-primary-color">Login</h2>

          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-semibold mb-2"
              >
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-semibold mb-2"
              >
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your password"
              />
            </div>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <div className="flex items-center justify-between">
              <button
                className="bg-primary-color hover:bg-secondary-color text-white font-bold py-2 px-4 rounded-lg transform hover:scale-105 transition duration-200 ease-in-out"
                type="submit"
              >
                Sign In
              </button>
              <a
                className="inline-block align-baseline font-semibold text-sm text-primary-color hover:text-blue-800 transition duration-300"
                href="/"
              >
                Forgot Password?
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
