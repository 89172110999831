import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "./dashboardLayout";

const DeliveryPartnersOnline = () => {
  const [deliveryPartners, setdeliveryPartners] = useState([]);

  useEffect(() => {
    const fetchMerchants = async () => {
      const token = sessionStorage.getItem("authToken");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/admin/allAvailableDeliveryPartners`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setdeliveryPartners(response.data.data.deliveryPartners);
        } else {
          console.error(
            "Error fetching deliveryPartners:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("API error:", error);
      }
    };

    fetchMerchants();
  }, []);

  return (
    <DashboardLayout>
      <>
        <div className="p-6 bg-gray-100 min-h-screen">
          <h1 className="text-3xl font-bold mb-6 text-gray-800">
            Online Delivery Partners
          </h1>
          {deliveryPartners.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {deliveryPartners.map((delivery) => (
                <div
                  key={delivery._id}
                  className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 hover:shadow-xl transition-shadow duration-300 ease-in-out flex flex-col justify-between"
                >
                  <div className="p-4 flex-grow">
                    <h2 className="bg-green-200 p-3 text-xl font-bold text-gray-800 mb-4 rounded-t-lg">
                      {delivery.name}
                    </h2>
                    <p className="text-gray-700 mb-4">
                      <strong>ID:</strong> {delivery._id}
                    </p>
                    <p className="text-gray-700 mb-4">
                      <strong>Pincode:</strong> {delivery.pinCode}
                    </p>
                    <p className="text-gray-700 mb-4">
                      <strong>Owner Mobile Number:</strong>{" "}
                      <span className="text-blue-700 font-medium">
                        {delivery.mobileNumber}
                      </span>
                    </p>
                    <a
                      href={`tel:${delivery.mobileNumber}`}
                      className="text-green-600 hover:text-green-800 font-medium text-sm"
                    >
                      Call Owner
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-600">
              No delivery partners online at the moment.
            </p>
          )}
        </div>
      </>
    </DashboardLayout>
  );
};

export default DeliveryPartnersOnline;
