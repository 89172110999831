import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
const StatusDropdown = ({ customerRequestId, currentStatus }) => {
  const [status, setStatus] = useState(currentStatus);
  const [isModified, setIsModified] = useState(false); // Track if the dropdown value has changed

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setIsModified(true); // Enable the OK button when the dropdown value changes
  };

  const handleUpdateStatus = async () => {
    try {
      const token = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/updateCustomerRequestStatus`,
        {
          customerRequestId,
          status,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Request status updated successfully!");
        setIsModified(false); // Disable the OK button after successful update
        window.location.reload();
      } else {
        toast.error("Failed to update the request status.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the request status.");
    }
  };

  return (
    <div className="flex items-center">
      <select
        value={status}
        onChange={handleStatusChange}
        className="mr-2 p-2 border rounded-md"
      >
        <option value="open">Open</option>
        <option value="pending">Pending</option>
        <option value="closed">Closed</option>
        <option value="invalid">Invalid</option>
      </select>
      {isModified && (
        <button
          onClick={handleUpdateStatus}
          className="px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          OK
        </button>
      )}
    </div>
  );
};

export default StatusDropdown;
