import React from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";

const PieChartComponent = ({
  deliveredCount,
  refundedCount,
  canceledCount,
  pendingCount,
}) => {
  const data = [
    { name: "Delivered", value: deliveredCount },
    { name: "Refunded", value: refundedCount },
    { name: "Cancelled", value: canceledCount },
    { name: "Pending", value: pendingCount },
  ];

  const COLORS = ["#4CAF50", "#FF9800", "#F44336", "#2196F3"];

  return (
    <div className="flex items-start justify-between p-6 bg-white shadow-md rounded-lg">
      <div>
        <h2 className="text-xl font-bold mb-4">Order Status</h2>
        <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
      <div className="ml-6">
        <h3 className="text-lg font-semibold">Details</h3>
        <ul className="mt-2">
          {data.map((entry, index) => (
            <li key={index} className="flex items-center mb-2">
              <span
                className="w-3 h-3 mr-2"
                style={{ backgroundColor: COLORS[index], borderRadius: "50%" }}
              />
              <span className="font-medium">
                {entry.name}: {entry.value}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PieChartComponent;
