import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

const Getqrscan = () => {
  const [qrScanNumber, setQrScanNumber] = useState(0);
  const [metaClicksNumber, setMetaClicksNumber] = useState(0);
  const [whatsappClicksNumber, setWhatsappClicksNumber] = useState(0);

  const fetchAnalysis = async () => {
    const token = sessionStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/superadmin/getQrScanAnalyses`,
        {},
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        const analyses = response.data.data.qrScanAnalyses;

        // Filter and set the visiting numbers for each analysis type
        const qrScanData = analyses.find(
          (item) => item.analysisType === "qrscan"
        );
        const metaClicksData = analyses.find(
          (item) => item.analysisType === "metaclicks"
        );
        const whatsappClicksData = analyses.find(
          (item) => item.analysisType === "whatsappclicks"
        );

        setQrScanNumber(qrScanData?.visitingNumber || 0);
        setMetaClicksNumber(metaClicksData?.visitingNumber || 0);
        setWhatsappClicksNumber(whatsappClicksData?.visitingNumber || 0);
      }
    } catch (error) {
      console.error("Failed to fetch QR Scan Analysis:", error);
    }
  };

  useEffect(() => {
    fetchAnalysis();
  }, []);

  return (
    <>
      <div>
        <p className="text-lg">
          QR Scan Visiting Number:{" "}
          <span className="text-red-600 font-bold">{qrScanNumber}</span>
        </p>
        <p className="text-lg">
          Meta Clicks Visiting Number:{" "}
          <span className="text-red-600 font-bold">{metaClicksNumber}</span>
        </p>
        <p className="text-lg">
          WhatsApp Clicks Visiting Number:{" "}
          <span className="text-red-600 font-bold">{whatsappClicksNumber}</span>
        </p>
      </div>
      <br />
    </>
  );
};

export default Getqrscan;
