import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const adminRole = sessionStorage.getItem("adminRole");
  const adminName = sessionStorage.getItem("adminName");

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/adminLogin");
  };

  return (
    <div
      className="bg-primary-color text-white flex flex-col w-64 transition-width duration-300 fixed h-full" // Set a fixed width
    >
      <div className="p-4">
        <div>
          <h1 className="text-xl font-semibold">{adminName}</h1>
          <p className="text-sm text-white">{adminRole}</p>
        </div>
      </div>
      <nav className="flex-grow px-2">
        <ul>
          <li className="mb-2">
            <NavLink
              to="/admin/dashboard"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/order-status"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Live Orders
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/add-product"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Add Product
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/merchants-online"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Merchants Online
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/merchants-registered"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Registered Merchants
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/online-delivery-partners"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Delivery Partners Online
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/split-settlement-details"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Split Settlement Details
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/support-requests"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Support Requests
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/categories"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Product Categories
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/medicines"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              Medicines
            </NavLink>
          </li>
          <li className="mb-2">
            <NavLink
              to="/admin/otc"
              className={({ isActive }) =>
                isActive
                  ? "block py-2 px-4 bg-secondary-color rounded"
                  : "block py-2 px-4 hover:bg-secondary-color rounded"
              }
            >
              OTCs
            </NavLink>
          </li>
          <div className="p-4">
            <button
              onClick={handleLogout}
              className="w-full py-2 bg-secondary-color hover:bg-accent-color hover:text-secondary-color rounded"
            >
              Logout
            </button>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
