import React, { useState } from "react";
import axios from "axios";
import DashboardLayout from "./dashboardLayout";

const SplitSettlementDetails = () => {
  const [orderId, setOrderId] = useState("");
  const [splitSettlementDetails, setSplitSettlementDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchSplitSettlementDetails = async () => {
    if (!orderId) {
      setError("Please enter a valid Order ID");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/admin/getOrderSplitSettlementDetails`,
        { orderId },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data.success) {
        setSplitSettlementDetails(
          response.data.data.orderSplitSettlementDetails
        );
      } else {
        setError("Failed to fetch split settlement details.");
      }
    } catch (error) {
      setError(
        "An error occurred while fetching the split settlement details."
      );
    } finally {
      setLoading(false);
    }
  };

  const timeConversion = (currentDate) => {
    if (!currentDate || isNaN(new Date(currentDate).getTime())) return null;

    let hours = currentDate.getUTCHours();
    let minutes = currentDate.getUTCMinutes().toString();

    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }

    let period = hours >= 12 ? "PM" : "AM";
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }

    const date = currentDate.getUTCDate();
    const month = currentDate.getUTCMonth() + 1;
    const year = currentDate.getUTCFullYear();

    return `${date}/${month}/${year} ${hours}:${minutes} ${period}`;
  };

  return (
    <DashboardLayout>
      <>
        <div className="p-6 bg-gray-100 min-h-screen ">
          <h1 className="text-4xl font-bold mb-8 text-gray-700">
            Split Settlement Details
          </h1>

          <div className="mb-8 flex gap-4 items-center">
            <input
              type="text"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              placeholder="Enter Order ID"
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={fetchSplitSettlementDetails}
              className="px-5 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-colors"
            >
              Search
            </button>
          </div>

          {loading && <p className="text-blue-600">Loading...</p>}
          {error && <p className="text-red-600 font-semibold">{error}</p>}

          {splitSettlementDetails && (
            <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl">
              {splitSettlementDetails.map((settlement) => (
                <div key={settlement._id} className="mb-8 border-b pb-4">
                  <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                    Settlement Details
                  </h2>
                  <div className="space-y-2">
                    <p>
                      <strong className="text-gray-700">
                        Settlement Amount:
                      </strong>{" "}
                      ₹{settlement.splitSettlementDetails.settlementAmount}
                    </p>
                    <p>
                      <strong className="text-gray-700">
                        Merchant Aggregator Sub Amount:
                      </strong>{" "}
                      ₹
                      {
                        settlement.splitSettlementDetails
                          .merchantAggregatorSubAmt
                      }
                    </p>
                    <p>
                      <strong className="text-gray-700">
                        Delivery Partner Aggregator Sub Amount:
                      </strong>{" "}
                      ₹
                      {
                        settlement.splitSettlementDetails
                          .deliveryPartnerAggregatorSubAmt
                      }
                    </p>
                    <p>
                      <strong className="text-gray-700">
                        Total Delivery Charges:
                      </strong>{" "}
                      ₹{settlement.splitSettlementDetails.totalDeliveryCharges}
                    </p>
                    <p>
                      <strong className="text-gray-700">
                        Handling Charges:
                      </strong>{" "}
                      ₹{settlement.splitSettlementDetails.handlingCharges}
                    </p>
                    <p>
                      <strong className="text-gray-700">Distance:</strong>{" "}
                      {settlement.splitSettlementDetails.distance} meters
                    </p>
                    <p>
                      <strong className="text-gray-700">Duration:</strong>{" "}
                      {settlement.splitSettlementDetails.duration} seconds
                    </p>
                    <p>
                      <strong className="text-gray-700">
                        Settlement Status:
                      </strong>{" "}
                      {settlement.splitSettlementDetails.settlementStatus}
                    </p>
                    <p>
                      <strong className="text-gray-700">Payment Method:</strong>{" "}
                      {settlement.splitSettlementDetails.paymentMethod}
                    </p>
                    <p>
                      <strong className="text-gray-700">Status:</strong>{" "}
                      {settlement.splitSettlementDetails.status}
                    </p>
                    <p>
                      <strong className="text-gray-700">Created On:</strong>{" "}
                      {timeConversion(
                        new Date(settlement.splitSettlementDetails.createdOn)
                      )}
                    </p>
                    <p>
                      <strong className="text-gray-700">Last Updated:</strong>{" "}
                      {timeConversion(
                        new Date(settlement.splitSettlementDetails.updatedAt)
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    </DashboardLayout>
  );
};

export default SplitSettlementDetails;
