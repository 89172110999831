import React from "react";
import DashboardLayout from "./dashboardLayout";
import { useEffect, useState } from "react";
import TotalOrders from "./totalOrders";
import axios from "axios";
import toast from "react-hot-toast";
import Qrscan from "./getQrScan";
export default function Dashboard() {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const getAdminRole = sessionStorage.getItem("adminRole");
  const [isLoading, setIsLoading] = useState(false);
  //   const [filterKey, setFilterKey] = useState(getAdminRole);
  const [filterKey, setFilterKey] = useState("all");
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const openNotificationModal = () => setIsNotificationModalOpen(true);
  const closeNotificationModal = () => setIsNotificationModalOpen(false);
  useEffect(() => {
    const fetchAdminDetails = async () => {
      setLoading(true);
      setError("");
      try {
        const token = sessionStorage.getItem("authToken");

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/superadmin/getAllAdmins?filterKey=${filterKey}`,
          {},
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 401) {
          setAdmins([]);
          setLoading(false);
          return;
        }

        const adminNames = response.data.data.admins.map((admin) => ({
          id: admin._id,
          name: admin.name,
          role: admin.role,
        }));
        setAdmins(adminNames);
      } catch (error) {
        setError("Failed to fetch admin details.");
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, [filterKey]);

  const deleteAdmin = async (adminId) => {
    const authToken = sessionStorage.getItem("authToken");

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/admin/superadmin/deleteAdmin`,
        {
          data: { adminId },
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Admin deleted successfully!");
        setAdmins((prevAdmins) =>
          prevAdmins.filter((admin) => admin.id !== adminId)
        );
      } else {
        toast.error("Failed to delete admin!");
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setError(
        "An error occurred while trying to delete admin. Please try again."
      );
    }
  };

  const sendPushNotification = async () => {
    if (!notificationTitle.trim() || !notificationBody.trim()) {
      alert("Both title and body are required!");
      return; // Stop execution if either field is empty
    }
    const authToken = sessionStorage.getItem("authToken");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/masteradmin/sendPromotionalPushNotification`,
        {
          messageTitle: notificationTitle,
          messageBody: notificationBody,
        },
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (response.data.success) {
        alert(response.data.message);
        toast.success(response.data.message);
        closeNotificationModal();
        setNotificationTitle("");
        setNotificationBody("");
      } else {
        toast.error("Failed to send notification!");
      }
    } catch (error) {
      toast.error("An error occurred while sending notification.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <>
        <TotalOrders />
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-4">Dashboard</h1>
          <Qrscan />
          {getAdminRole === "masteradmin" && (
            <>
              <button
                onClick={openNotificationModal}
                className="px-4 py-2 mr-4 font-bold text-white bg-purple-500 rounded-full hover:bg-purple-700 focus:outline-none focus:shadow-outline"
              >
                Send Push Notification
              </button>
            </>
          )}
          {isNotificationModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <h2 className="text-2xl font-bold mb-4">
                  Send Push Notification
                </h2>

                {/* Form content - only displayed when not loading */}
                {!isLoading && (
                  <>
                    <div className="mb-4">
                      <label className="block text-sm font-bold mb-2">
                        Title
                      </label>
                      <input
                        type="text"
                        value={notificationTitle}
                        onChange={(e) => setNotificationTitle(e.target.value)}
                        className="w-full px-4 py-2 border rounded"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-bold mb-2">
                        Body
                      </label>
                      <textarea
                        value={notificationBody}
                        onChange={(e) => setNotificationBody(e.target.value)}
                        className="w-full px-4 py-2 border rounded"
                        rows="4"
                        required
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={sendPushNotification}
                        className="px-4 py-2 mr-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
                        disabled={isLoading} // Disable button while loading
                      >
                        Ok
                      </button>
                      <button
                        onClick={closeNotificationModal}
                        className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                        disabled={isLoading} // Disable button while loading
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}

                {/* Loader - displayed when isLoading is true */}
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 w-full h-full">
                    <div className="w-10 h-10 border-4 border-gray-300 border-t-green-500 border-solid rounded-full animate-spin"></div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* <CreateAdmin isOpen={isModalOpen} onClose={closeModal} /> */}

          {getAdminRole === "superadmin" ||
            (getAdminRole === "masteradmin" && (
              <>
                <div className="mb-4">
                  <select
                    value={filterKey}
                    onChange={(e) => setFilterKey(e.target.value)}
                    className="mt-4 px-4 py-2 font-bold text-white bg-blue-500 rounded-full focus:outline-none focus:shadow-outline"
                  >
                    <option value="admin">Admin</option>
                    <option value="superadmin">Superadmin</option>

                    <option value="all">All</option>
                  </select>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p className="text-red-500">{error}</p>}

                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead>
                      <tr className="w-full bg-gray-800 text-white">
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                          Name
                        </th>
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                          Role
                        </th>
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-700">
                      {admins.length > 0 ? (
                        admins.map((admin) => (
                          <tr key={admin.id} className="hover:bg-gray-100">
                            <td className="py-3 px-4">{admin.name}</td>
                            <td className="py-3 px-4">{admin.role}</td>
                            <td className="py-3 px-4">
                              {admin.role !== "superadmin" &&
                                admin.role !== "masteradmin" && (
                                  <button
                                    onClick={() => deleteAdmin(admin.id)}
                                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                                  >
                                    Delete
                                  </button>
                                )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="py-3 px-4" colSpan="3">
                            No admins found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
        </div>
      </>
    </DashboardLayout>
  );
}
